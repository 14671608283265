import CreateBackup from "../views/Tools/CreateBackup.js";
import DataSharing from "../views/Settings/DataSharing.js";
import UserManagement from "../views/Settings/UserManagement.js";
import UserRestricted from "../views/Settings/UserRestricted.js";
import RestoreBackup from "../views/Tools/RestoreBackup";
import AutoBackups from "../views/Tools/AutoBackups";
import RevertLastRestored from "../views/Tools/RevertLastRestored";
import Logout from "../views/Auth/Logout.js";
import EmployeesDetailsNav from "../views/Employees/Details/EmployeesDetailsNav.js";
import Login from "../views/Auth/Login.js";
import ResetPassword from "../views/Auth/ResetPassword.js";
import Trial from "../views/Auth/Trial.js";
import ForgotPassword from "../views/Auth/ForgotPassword.js";
import CompanyAllowances from "../views/Company/CompanyAllowances.js";
import GLCodes from "../views/Company/GLCodes.js";
import CompanyDetails from "../views/Company/CompanyDetails.js";
import CompanyDepartments from "../views/Company/CompanyDepartments.js";
import Reports from "../views/Reports/Reports.js";
import reportNames from "../variables/reportNames.js";
import NewCompanyBackupPrompt from "../views/Entities/NewCompanyBackupPrompt.js";
import SelectEntity from "../views/Entities/SelectEntity";
import AddEntity from "../views/Entities/AddEntity";
import DeleteEntity from "../views/Entities/DeleteEntity";
import PaySelectionView from "../views/Pays/PaySelectionView";
import SetProcessedDateView from "../views/Pays/SetProcessedDateView";
import SBRSubmissionsView from "../views/Company/SBRSubmissionsView";
import SBRSubmissionView from "../views/Company/SBRSubmissionView";
import ShiftTabView from "../views/Company/ShiftTabView";
import OnlineBackupsView from "../views/Tools/OnlineBackupsView";
import LeaveBalance from "../views/Company/LeaveBalance";
import React from "react";
import AwardsTabView from "../views/Company/AwardsTabView";
import PayRateGroups from "../views/Company/PayRateGroups";
import BankAccountsView from "../views/Company/BankAccountsView";
import PayDates from "../views/Company/PayDates";
import EditPayView from "../views/Pays/EditPayView";
import CreatePaysView from "../views/Pays/CreatePaysView";
import DeletePaysView from "../views/Pays/DeletePaysView";
import YearToDateTab from "../views/Employees/YTD/YearToDateTab";
import ShiftsTab from "../views/Employees/Shifts/ShiftsTab";
import SuperFundProviders from "../views/Settings/SuperFundProviders";
import Banks from "../views/Settings/Banks";
import DisplayTimeSettings from "../views/Settings/DisplayTimeSettings";
import OtherSettings from "../views/Settings/OtherSettings";
import SendBackupToSupport from "../views/Help/SendBackupToSupport";
import LoginToMyAccount from "../views/Help/LoginToMyAccount";
import CreateSuperFundOnlineSearch from "../views/SuperStream/CreateSuperFundOnlineSearch";
import SuperStreamContributionWizard from "../views/SuperStream/SuperStreamContribution/SuperStreamContributionWizard.js";
import SuperStreamDirectEntryWizard from "../views/SuperStream/SuperStreamDirectEntryWizard/SuperStreamDirectEntryWizard.js";
import TimeclockSyncView from "../views/Common/TimeclockSyncView.js";
import LeaveRequestsView from "../views/Common/LeaveRequestsView.js";
import LeaveRequestView from "../views/Common/LeaveRequestView.js";
import TimeclockRoundingOptions from "../views/Common/TimeclockRoundingOptions.js";
import EditSuperFundDeposit from "../views/SuperFundDeposits/EditSuperFundDeposit.js";
import BlankSuperFundDeposit from "../views/SuperFundDeposits/BlankSuperFundDeposit.js";
import PersonalTab from "../views/Employees/Details/PersonalTab";
import KinTab from "../views/Employees/Details/KinTab";
import BankAccountsTab from "../views/Employees/Details/BankAccountsTab";
import DepartmentsTab from "../views/Employees/Details/DepartmentsTab";
import EmploymentDatesTab from "../views/Employees/Details/EmploymentDatesTab";
import EmailOptionsTab from "../views/Employees/Details/EmailOptionsTab";
import NotesTab from "../views/Employees/Details/NotesTab";
import OnlinePortalTab from "../views/Employees/Details/OnlinePortalTab";
import PaySettingsNav from "../views/Employees/PaySettings/PaySettingsNav";
import PayRatesTab from "../views/Employees/PaySettings/PayRatesTab";
import SpecialUserDefinedPayRates from "../views/Employees/PaySettings/SpecialUserDefinedPayRates";
import SalarySacrifice from "../views/Employees/PaySettings/SalarySacrifice";
import PayslipOptions from "../views/Employees/PaySettings/PayslipOptions";
import LeaveNav from "../views/Employees/Leave/LeaveNav";
import LeaveSettings from "../views/Employees/Leave/LeaveSettings";
import LeaveEntitlementAdjustments from "../views/Employees/Leave/LeaveEntitlementAdjustments";
import LeaveCalculator from "../views/Employees/Leave/LeaveCalculator";
import CustomLeaveAccruals from "../views/Employees/Leave/CustomLeaveAccruals";
import AdvancedProRataLeaveSettings from "../views/Employees/Leave/AdvancedProRataLeaveSettings";
import SuperNav from "../views/Employees/Super/SuperNav";
import SuperContributions from "../views/Employees/Super/SuperContributions";
import SuperFund from "../views/Employees/Super/SuperFund";
import NewEmployee from "../views/Employees/NewEmployee";
import ImportEmployees from "../views/Employees/ImportEmployees";
import CopyEmployee from "../views/Employees/CopyEmployee";
import ReinstateEmployee from "../views/Employees/ReinstateEmployee";
import Allowances from "../views/Employees/AllowanceDeduction/Allowances";
import AllowanceDeductionNav from "../views/Employees/AllowanceDeduction/AllowanceDeductionNav";
import Bonuses from "../views/Employees/AllowanceDeduction/Bonuses";
import Deductions from "../views/Employees/AllowanceDeduction/Deductions";
import TaxNav from "../views/Employees/Tax/TaxNav";
import TaxSettings from "../views/Employees/Tax/TaxSettings";
import JobKeeperHistory from "../views/Employees/Tax/JobKeeperHistory";
import MedicareLevyExemption from "../views/Employees/Tax/MedicareLevyExemption";
import TaxOffsets from "../views/Employees/Tax/TaxOffsets";
import ReportableFringeBenefits from "../views/Employees/Tax/ReportableFringeBenefits";
import ReportableFringeBenefitsEdit from "../views/Employees/Tax/ReportableFringeBenefitsEdit";
import SpecialTaxRates from "../views/Employees/Tax/SpecialTaxRates";
import YTDNav from "../views/Employees/YTD/YTDNav";
import ShiftsNav from "../views/Employees/Shifts/ShiftsNav";
import EditShift from "../views/Employees/Shifts/EditShift";
import FinancialYears from "../views/Company/FinancialYears";
import PayslipsWizard from "../views/Pays/PayslipsWizard/PayslipsWizard";
import PayDetailsWizard from "../views/Pays/PayDetailsWizard/PayDetailsWizard";
import CurrencyRequirementsWizard from "../views/Pays/CurrencyRequirementsWizard/CurrencyRequirementsWizard";
import DirectEntryWizard from "../views/Pays/DirectEntryWizard/DirectEntryWizard";
import SingleTouchWizard from "../views/Pays/SingleTouch/SingleTouchWizard/SingleTouchWizard";
import SingleTouchMailboxView from "../views/Pays/SingleTouch/SingleTouchMailboxView";
import SingleTouchMailboxEvent from "../views/Pays/SingleTouch/SingleTouchMailboxEvent";
import SuperStreamMailboxView from "../views/SuperStream/SuperStreamMailboxView";
import SuperStreamMailboxMessage from "../views/SuperStream/SuperStreamMailboxMessage";
import LoginAsCustomer from "../views/Admin/LoginAsCustomer";
import ServerLogs from "../views/Admin/ServerLogs";
import LeaveRecalcWizardTool from "../views/Tools/LeaveRecalcWizardTool/LeaveRecalcWizardTool";
import SuperFundDeposits from "../views/SuperFundDeposits/SuperFundDeposits";
import CreateMultipleSuperFundDeposits from "../views/SuperFundDeposits/CreateMultipleSuperFundDeposits";
import ChangeHistoryView from "../views/Tools/ChangeHistoryView.js";

// import all the reportNames components dynamically
Object.keys(reportNames).map((reportName, key) => {
  React.lazy(() =>
    import(
      `views/Reports/ReportTemplates/${
        reportNames[reportName]["component"] + ".js"
      }`
    )
  );
});

const getReportsArray = () => {
  // Reports are a special case, they are not in the routes array, but are in the reportNames array
  // This is because they are dynamically generated
  // Add them to routes here.
  let results = [];
  Object.keys(reportNames).map((reportName, key) => {
    results.push({
      path: "/reports/" + reportName,
      name: reportNames[reportName]["name"],
      report: reportName,
      hide: true,
      short_description: reportNames[reportName]["short_description"],
      icon: "ni ni-chart-bar-32 text-yellow",
      component: reportNames[reportName]["component"],
      layout: "/admin",
    });
  });
  return results;
};

// Use hide=true if you do not want the route to appear in the navbar/sidebar
var routes = [
  {
    path: "/entities",
    name: "Entities",
    icon: "ni ni-building text-yellow",
    layout: "/admin",
    options: [
      {
        name: "Select Company",
        icon: "ni ni-shop text-blue fa-xl width-40px ",
        path: "/entities/select",
        component: SelectEntity,
        layout: "/admin",
      },
      {
        name: "Add Company",
        icon: "fa-regular fa-plus text-blue fa-xl width-40px ",
        path: "/entities/add",
        component: AddEntity,
      },
      {
        name: "Delete Company",
        icon: "fa-regular fa-times text-blue fa-xl width-40px ",
        path: "/entities/delete",
        component: DeleteEntity,
      },
    ],
  },
  {
    path: "/employees",
    name: "Employees",
    icon: "ni ni-single-02 text-yellow",
    layout: "/admin",
    options: [
      {
        path: "/employees/new_employee",
        name: "Add New Employee",
        tags: ["Create employee", "new starter"],
        layout: "/admin",
        hide: true,
        component: NewEmployee,
      },
      {
        path: "/employees/import_employees",
        name: "Import Employees From CSV",
        tags: ["Create employee", "CSV Import"],
        layout: "/admin",
        hide: true,
        component: ImportEmployees,
      },
      {
        path: "/employees/copy_employee",
        name: "Copy Employee",
        tags: ["Copy employee", "one company to another"],
        layout: "/admin",
        hide: true,
        component: CopyEmployee,
      },
      {
        path: "/employees/reinstate",
        name: "Reinstate Employee",
        tags: ["Reinstate", "returning"],
        layout: "/admin",
        hide: true,
        component: ReinstateEmployee,
      },
      {
        name: "Details",
        label: "Personal",
        icon: "fa-regular fa-user fa-xl width-40px text-blue",
        path: "/employees/details/personal",
        tags: [
          "employee details",
          "address",
          "personal details",
          "personal information",
          "personal",
          "email",
          "phone",
        ],
        component: EmployeesDetailsNav,
        subComponent: PersonalTab,
        short_description:
          "Edit an employee's personal details, including their name, photo, address and contact details.",
        layout: "/admin",
      },
      {
        path: "/employees/details/kin",
        name: "Kin",
        layout: "/admin",
        tags: ["employee details", "kin", "next of kin", "emergency contact"],
        icon: "fa-regular fa-users fa-xl width-40px",
        hide: true,
        short_description:
          "Emergency contact details for an employee. Used in the event of an emergency, such as an accident at work.",
        component: EmployeesDetailsNav,
        subComponent: KinTab,
      },
      {
        path: "/employees/details/bank_accounts",
        name: "Bank Accounts",
        layout: "/admin",
        tags: [
          "employee details",
          "bank",
          "bank account",
          "bank accounts",
          "direct entry",
          "banking",
        ],
        short_description:
          "View & edit an employee's bank account details. Used for the direct deposit of pay.",
        icon: "fa-regular fa-building-columns fa-xl width-40px",
        hide: true,
        component: EmployeesDetailsNav,
        subComponent: BankAccountsTab,
      },
      {
        path: "/employees/details/departments",
        name: "Departments",
        tags: [
          "employee details",
          "departments",
          "department",
          "cost center",
          "cost centers",
        ],
        icon: "fa-regular fa-chart-pie fa-xl width-40px",
        short_description:
          "Assign an employee to one or more departments. Useful for reporting purposes.",
        layout: "/admin",
        hide: true,
        component: EmployeesDetailsNav,
        subComponent: DepartmentsTab,
      },
      {
        path: "/employees/details/employment_dates",
        name: "Employment Dates",
        tags: [
          "employment",
          "employment dates",
          "start date",
          "end date",
          "start",
          "end",
          "termination",
          "termination date",
          "termination dates",
        ],
        layout: "/admin",
        short_description:
          "View & edit an employee's start date or termination date.",
        icon: "ni ni-calendar-grid-58 fa-xl width-40px",
        hide: true,
        component: EmployeesDetailsNav,
        subComponent: EmploymentDatesTab,
      },
      {
        path: "/employees/details/email_options",
        name: "Email Options",
        icon: "fa-regular fa-envelope fa-xl ",
        tags: [
          "email",
          "email options",
          "email address",
          "email addresses",
          "payslip",
        ],
        layout: "/admin",
        short_description: "Manage email settings for individual employees.",
        hide: true,
        component: EmployeesDetailsNav,
        subComponent: EmailOptionsTab,
      },
      {
        path: "/employees/details/notes",
        name: "Notes",
        tags: ["notes", "note", "comments", "comment", "history"],
        layout: "/admin",
        icon: "fa-regular fa-notes fa-xl width-40px",
        short_description: "Add dated notes to an employee's record.",
        hide: true,
        component: EmployeesDetailsNav,
        subComponent: NotesTab,
      },
      {
        path: "/employees/details/online_portal",
        name: "Online Portal",
        tags: [
          "online",
          "portal",
          "online portal",
          "employee portal",
          "timeclock",
          "leave request",
          "time clock",
          "time sheet",
          "timesheet",
          "bank accounts",
          "details",
        ],
        layout: "/admin",
        icon: "fa-regular fa-cloud fa-xl width-40px",
        short_description:
          "Manage an employee's access level to different online portal features within the LP website.",
        hide: true,
        component: EmployeesDetailsNav,
        subComponent: OnlinePortalTab,
      },
      {
        name: "Pay Settings",
        label: "Pay Rates",
        tags: [
          "pay",
          "pay rates",
          "salary",
          "wage",
          "rates",
          "pay settings",
          "default pay",
          "award",
          "classification",
          "hours",
          "custom pay",
        ],
        icon: "fa-regular fa-dollar-sign fa-xl width-40px text-blue",
        path: "/employees/pay_settings/pay_rates",
        short_description:
          "Manage pay rates and other pay settings for individual employees.",
        component: PaySettingsNav,
        subComponent: PayRatesTab,
      },
      {
        name: "Special User-Defined Pay Rates",
        path: "/employees/pay_settings/special_pay_rates",
        tags: [
          "pay",
          "pay rates",
          "wage",
          "rates",
          "pay settings",
          "default pay",
          "award",
          "classification",
          "hours",
          "custom pay",
        ],
        icon: "fa-regular fa-sparkles fa-xl width-40px",
        hide: true,
        short_description: "Manage custom pay rates for individual employees.",
        component: PaySettingsNav,
        subComponent: SpecialUserDefinedPayRates,
      },
      {
        name: "Salary/Wage Sacrifice",
        path: "/employees/pay_settings/salary_sacrifice",
        tags: [
          "resc",
          "sacrifice",
          "reportable employer super contributions",
          "extra super",
        ],
        icon: "fa-regular fa-hand-holding-dollar fa-xl width-40px",
        short_description:
          "View and edit employee salary sacrifice arrangements and super sacrifices which get deducted from a pay before tax.",
        hide: true,
        component: PaySettingsNav,
        subComponent: SalarySacrifice,
      },
      {
        name: "Payslip Options",
        path: "/employees/pay_settings/payslip_options",
        tags: [
          "payslip",
          "leave",
          "pay slip",
          "YTD",
          "year to date",
          "year-to-date",
        ],
        icon: "fa-regular fa-envelope-open-dollar fa-xl width-40px",
        short_description:
          "Configure what should or should not be included on an employee's payslip.",
        hide: true,
        component: PaySettingsNav,
        subComponent: PayslipOptions,
      },
      {
        name: "Tax Rates",
        label: "Tax Settings",
        tags: [
          "tax",
          "tax rates",
          "tax settings",
          "tax file number",
          "TFN",
          "voluntary agreement",
        ],
        icon: "fa-regular fa-calculator fa-xl text-blue width-40px",
        path: "/employees/tax/tax_settings",
        short_description:
          "Manage tax settings and tax file number for individual employees.",
        component: TaxNav,
        subComponent: TaxSettings,
      },
      {
        name: "JobKeeper/JobMaker History",
        icon: "fa-regular fa-user-helmet-safety fa-xl width-40px text-blue",
        tags: [
          "jobkeeper",
          "jobmaker",
          "history",
          "jobkeeper history",
          "job keeper",
          "job maker",
        ],
        hide: true,
        path: "/employees/tax/jobkeeper_jobmaker_history",
        short_description: "View JobKeeper and JobMaker submission history.",
        component: TaxNav,
        subComponent: JobKeeperHistory,
      },
      {
        name: "Medicare Levy Exemption",
        icon: "fa-regular fa-truck-medical fa-xl width-40px text-blue",
        tags: ["medicare", "medicare levy", "medicare levy exemption", "mle"],
        hide: true,
        path: "/employees/tax/medicare_levy_exemption",
        short_description:
          "Manage Medicare Levy Exemption settings for eligible employees.",
        component: TaxNav,
        subComponent: MedicareLevyExemption,
      },
      {
        name: "Tax Offsets",
        icon: "fa-regular fa-money-check-dollar-pen fa-xl width-40px text-blue",
        tags: ["tax", "tax offsets", "offsets", "tax offset", "senior"],
        hide: true,
        short_description: "Manage annual tax offsets for eligible employees.",
        path: "/employees/tax/tax_offsets",
        component: TaxNav,
        subComponent: TaxOffsets,
      },
      {
        name: "Reportable Fringe Benefits",
        icon: "fa-regular fa-handshake fa-xl width-40px text-blue",
        hide: true,
        path: "/employees/tax/reportable_fringe_benefits",
        tags: [
          "fringe",
          "benefits",
          "reportable fringe benefits",
          "rfb",
          "fbt",
        ],
        short_description:
          "Manage employees' reportable fringe benefits tax settings.",
        component: TaxNav,
        subComponent: ReportableFringeBenefits,
      },
      {
        name: "Special Tax Rates",
        icon: "fa fa-question fa-xl width-40px text-blue",
        tags: [
          "working holiday",
          "home country",
          "visa",
          "shearing",
          "horticulture",
        ],
        short_description:
          "Manage special tax settings such as the working holiday and horticulture/shearing tax scales.",
        hide: true,
        path: "/employees/tax/special_tax_rates",
        component: TaxNav,
        subComponent: SpecialTaxRates,
      },
      {
        name: "Leave",
        label: "Leave Settings",
        tags: [
          "holiday leave",
          "leave",
          "leave settings",
          "leave accruals",
          "sick leave",
          "annual leave",
          "leave entitlements",
          "long service",
          "lsl",
        ],
        icon: "fa-regular fa-plane fa-xl width-40px text-blue",
        short_description:
          "Configure leave accrual settings for each employee.",
        path: "/employees/leave/leave_settings",
        component: LeaveNav,
        subComponent: LeaveSettings,
      },
      {
        name: "Leave Entitlement Adjustments",
        icon: "fa-regular fa-edit fa-xl width-40px text-blue",
        hide: true,
        tags: [
          "leave balance",
          "leave entitlement",
          "leave adjustment",
          "how much leave",
        ],
        path: "/employees/leave/leave_entitlement_adjustments",
        short_description:
          "Manage leave balances and leave adjustments for individual employees.",
        component: LeaveNav,
        subComponent: LeaveEntitlementAdjustments,
      },
      {
        name: "Custom Leave",
        icon: "ni ni-world-2 fa-xl width-40px",
        hide: true,
        tags: [
          "custom leave",
          "rdo",
          "lieu",
          "TIL",
          "TOIL",
          "TOILS",
          "special leave",
        ],
        short_description:
          "Create and manage custom leave types such as RDOs or TOIL for individual employees.",
        path: "/employees/leave/custom_leave",
        component: LeaveNav,
        subComponent: CustomLeaveAccruals,
      },
      {
        name: "Advanced Pro Rate Leave Settings",
        icon: "ni ni-settings-gear-65 fa-xl width-40px",
        hide: true,
        tags: [
          "pro rate",
          "pro-rate",
          "pro rata",
          "pro-rata",
          "advanced leave",
          "workers comp",
          "maternity leave",
          "paternity leave",
          "parental leave",
          "leave settings",
          "jury duty",
          "other leave",
        ],
        short_description:
          "Configure how pro rata leave should accrue for each system leave type.",
        path: "/employees/leave/advanced_pro_rate_leave_settings",
        component: LeaveNav,
        subComponent: AdvancedProRataLeaveSettings,
      },
      {
        name: "Leave Calculator",
        icon: "fa-regular fa-calculator fa-xl width-40px text-blue",
        hide: true,
        tags: [
            "leave calculator",
            "calculate leave",
        ],
        path: "/employees/leave/:paramLeaveType",
        short_description: "Calculate leave entitlements for employees.",
        component: LeaveCalculator,
      },
      {
        name: "Super",
        label: "Super Contributions",
        tags: [
          "super contributions",
          "super",
          "superannuation",
          "super settings",
          "compulsory super",
        ],
        icon: "fa-regular fa-hand-holding-seedling fa-xl width-40px text-blue",
        short_description:
          "Configure superannuation settings for each employee.",
        path: "/employees/super/superannuation_contributions",
        component: SuperNav,
        subComponent: SuperContributions,
      },
      {
        name: "Super Funds",
        tags: [
          "super funds",
          "super",
          "superannuation",
          "super settings",
          "member number",
          "USI",
        ],
        icon: "fa-regular fa-building-columns fa-xl width-40px text-blue",
        hide: true,
        short_description:
          "Manage superannuation funds and member numbers for each employee.",
        path: "/employees/super/superannuation_fund",
        component: SuperNav,
        subComponent: SuperFund,
      },
      {
        name: "Allowances/Deductions",
        label: "Allowances",
        tags: ["travel", "laundry", "meal", "transport"],
        icon: "fa-regular fa-plus-minus fa-xl width-40px text-blue",
        buttonIcon: "fa-regular fa-hand-holding-dollar fa-xl width-40px",
        path: "/employees/allowance_deduction/allowances",
        short_description: "Add an allowance for an employee.",
        component: AllowanceDeductionNav,
        subComponent: Allowances,
      },
      {
        name: "Deductions",
        hide: true,
        tags: [
          "deduction",
          "deduct",
          "deducts",
          "child support",
          "bank account",
        ],
        icon: "fa-regular fa-scissors fa-xl width-40px text-blue",
        short_description: "Add a deduction for an employee.",
        path: "/employees/allowance_deduction/deductions",
        component: AllowanceDeductionNav,
        subComponent: Deductions,
      },
      {
        name: "Bonuses",
        icon: "fa-regular fa-money-bill-trend-up fa-xl width-40px text-blue",
        hide: true,
        path: "/employees/allowance_deduction/bonuses",
        short_description: "Add a bonus or commission item for an employee.",
        tags: ["bonus", "incentive", "commission"],
        component: AllowanceDeductionNav,
        subComponent: Bonuses,
      },
      {
        name: "Year to Date",
        label: "YTD Summary",
        icon: "ni ni-calendar-grid-58 fa-xl width-40px text-blue",
        path: "/employees/ytd/summary",
        tags: ["year to date", "ytd", "year to date summary"],
        short_description:
          "View year to date totals for an employee by financial year and income stream.",
        component: YTDNav,
        subComponent: YearToDateTab,
      },
      {
        name: "Shifts",
        tags: ["shifts", "shift", "roster", "rosters", "rostering"],
        icon: "ni ni-watch-time text-blue fa-xl width-40px",
        path: "/employees/shifts",
        short_description:
          "View and manage employee shifts, including pay items for each shift and auto-rostering shift repeats.",
        component: ShiftsNav,
        subComponent: ShiftsTab,
      },
    ],
  },
  {
    name: "Reportable Fringe Benefits (Edit)",
    icon: "fa fa-handshake fa-xl width-40px text-blue",
    hide: true,
    options: [],
    layout: "/admin",
    hide_from_search: true,
    path: "/employees/tax/reportable_fringe_benefits_edit",
    component: ReportableFringeBenefitsEdit,
  },
  {
    name: "Employee Shift (Edit)",
    icon: "ni ni-watch-time fa-xl width-40px text-blue",
    hide: true,
    options: [],
    layout: "/admin",
    hide_from_search: true,
    path: "/employees/shifts/edit_shift",
    component: EditShift,
  },
  {
    path: "/tools",
    hide_from_search: true,
    name: "Tools",
    showWithoutCompany: true,
    icon: "fa-regular fa-screwdriver-wrench text-yellow fa-2xl",
    layout: "/admin",
    options: [
      {
        path: "/create_backup",
        name: "Create Backup",
        tags: ["backup", "save", "data", "tools"],
        icon: "fa-regular fa-folder-arrow-down fa-xl width-40px text-blue",
        short_description:
          "Create a backup of your complete payroll data, and download it to your computer.",
        component: CreateBackup,
        layout: "/admin",
      },

      {
        path: "/restore_backup",
        name: "Restore Backup",
        tags: ["backup", "restore", "load", "data", "tools"],
        short_description:
          "Restore a backup of your payroll data (.lpb file) from your computer.",
        icon: "fa-regular fa-folder-arrow-up text-blue fa-xl width-40px ",
        component: RestoreBackup,
        layout: "/admin",
        showWithoutCompany: true,
      },
      {
        path: "/online_backups",
        name: "Online Backups",
        tags: [
          "online backup",
          "restore",
          "load",
          "data",
          "save",
          "shared data",
          "tools",
        ],
        short_description:
          "Create and restore backups of your payroll data from our secure cloud server.",
        icon: "fa-regular fa-cloud-arrow-up text-blue fa-xl width-40px ",
        component: OnlineBackupsView,
        layout: "/admin",
        showWithoutCompany: true,
      },
      {
        path: "/recalculate_leave",
        name: "Recalculate leave accruals on Pays",
        tags: ["leave", "recalculation", "accrual", "pays", "tools"],
        short_description:
          "Recalculate leave accruals across pays for your employees.",
        icon: "fa-light fa-plane-circle-check text-blue fa-xl width-40px",
        component: LeaveRecalcWizardTool,
        layout: "/admin",
      },
      {
        path: "/change_history",
        name: "Change History",
        tags: ["change", "history", "add", "delete", "update"],
        short_description: "View any changes that have been made to your data.",
        icon: "fa-light fa-book text-blue fa-xl width-40px ",
        component: ChangeHistoryView,
        layout: "/admin",
      },
      {
        path: "/auto_backups",
        name: "Automatic Backups",
        icon: "fa-regular fa-ambulance fa-xl width-40px text-blue",
        tags: ["backup", "save", "data", "tools"],
        component: AutoBackups,
        short_description:
          "View automatic/emergency backups of your payroll data that have been created by the system.",
        layout: "/admin",
        options: [],
      },
      {
        path: "/revert_last_restore",
        name: "Revert Last Restored Backup",
        icon: "fa-regular fa-rotate-left fa-xl width-40px text-blue",
        tags: ["backup", "save", "data", "tools", "undo", "restore"],
        component: RevertLastRestored,
        short_description:
          "Undo the last backup restoration and revert all data to the previous state.",
        layout: "/admin",
        options: [],
      },
    ],
  },
  {
    name: "Company",
    icon: "ni ni-building text-yellow",
    layout: "/admin",
    hide_from_search: true,
    path: "/company",
    options: [
      {
        name: "Details",
        icon: "fa-regular fa-memo-circle-info fa-xl width-40px text-blue",
        path: "/company/details",
        tags: ["company", "details", "information", "abn", "acn"],
        component: CompanyDetails,
        short_description:
          "View and edit your company/business details such as ABN and contact person.",
        layout: "/admin",
      },
      {
        name: "Allowances",
        icon: "fa-regular fa-hand-holding-dollar fa-xl width-40px text-blue",
        tags: ["allowance", "allowances", "travel", "laundry", "meal"],
        path: "/company/allowances",
        short_description:
          "Add an allowance which can be shared between multiple employees. ",
        component: CompanyAllowances,
      },
      {
        name: "GL Codes",
        icon: "fa-regular fa-receipt fa-xl width-40px text-blue",
        tags: ["accounting", "gl postings", "gl codes", "ledger", "export"],
        path: "/company/gl_codes",
        short_description:
          "Configure GL codes and CSV settings for exporting to your accounting software.",
        component: GLCodes,
      },
      // {
      //   name: "Financial Years",
      //   icon: "fa-regular fa-fence fa-xl width-40px text-blue",
      //   tags: ["department", "division", "cost center"],
      //   path: "/company/financial_years",
      //   short_description:
      //     "Split and manage financial year start and end dates, if necessary.",
      //   component: FinancialYears,
      // },
      {
        name: "Bank Accounts",
        icon: "fa-regular fa-building-columns fa-xl width-40px text-blue",
        tags: ["bank account", "banking", "user ID"],
        path: "/company/bank_accounts",
        short_description:
          "Manage your company's bank accounts for paying staff and super.",
        component: BankAccountsView,
      },
      {
        name: "Departments",
        icon: "fa-regular fa-chart-pie fa-xl width-40px text-blue",
        tags: ["department", "division", "cost center"],
        path: "/company/departments",
        short_description: "Add a department or division to your company.",
        component: CompanyDepartments,
      },
      {
        name: "Awards",
        icon: "fa-regular fa-trophy text-blue fa-xl width-40px",
        path: "/company/awards",
        short_description:
          "Add an award record to your company which can then be linked to your employees.",
        tags: ["award", "award settings"],
        component: AwardsTabView,
      },
      {
        name: "Pay Rate Groups/Classifications",
        icon: "fa-regular fa-money-check-dollar-pen text-blue fa-xl width-40px",
        path: "/company/pay_rate_groups",
        short_description:
          "Share pay rates or award classification labels between multiple employees.",
        tags: [
          "award",
          "classification",
          "shared pay rates",
          "pay rate",
          "pay rate group",
        ],
        component: PayRateGroups,
      },
      {
        name: "Pay Dates",
        icon: "fa-regular fa-calendar-range text-blue fa-xl width-40px",
        path: "/company/pay_dates",
        short_description:
          "Modify pay run date preferences for upcoming pay periods.",
        tags: ["pay dates", "schedule", "end date", "pay period"],
        component: PayDates,
      },
      {
        name: "Leave Balances",
        icon: "fa-regular fa-plane text-blue fa-xl width-40px",
        path: "/company/leave_balances",
        short_description:
          "Create a custom leave type which can then be linked to your employees.",
        tags: ["custom leave", "leave balance", "RDO", "TIL", "lieu"],
        component: LeaveBalance,
      },
      {
        name: "SBR Submissions",
        icon: "fa-regular fa-envelopes-bulk text-blue fa-xl width-40px",
        path: "/company/sbr_submissions",
        short_description:
          "View legacy SBR submissions made prior to Single Touch Payroll.",
        component: SBRSubmissionsView,
      },
      {
        name: "SBR Submission",
        icon: "ni ni-send text-blue",
        path: "/company/sbr_submission",
        hide_from_search: true,
        hide: true,
        component: SBRSubmissionView,
      },
      {
        name: "Shifts",
        icon: "fa-regular fa-watch text-blue fa-xl width-40px",
        tags: ["shift", "roster", "timeclock", "time clock"],
        path: "/company/shifts",
        short_description:
          "Manage base shift templates and employee connections, used to create more specific employee and roster shifts.",
        component: ShiftTabView,
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    hide_from_search: true,
    layout: "/auth",
  },
  {
    path: "/reset-password",
    name: "Reset Password",
    icon: "ni ni-key-25 text-info",
    component: ResetPassword,
    hide_from_search: true,
    layout: "/auth",
  },
  {
    path: "/trial",
    name: "Start A Free Trial",
    icon: "fa-regular fa-microscope text-info",
    component: Trial,
    hide_from_search: true,
    layout: "/auth",
  },
  {
    path: "/forgot_password",
    name: "Forgot Password",
    icon: "fa-regular fa-question text-info",
    component: ForgotPassword,
    hide_from_search: true,
    layout: "/auth",
  },
  {
    path: "/login_as_customer",
    name: "Login As Customer",
    icon: "ni ni-key-25 text-info",
    component: LoginAsCustomer,
    hide_from_search: true,
    hide: true,
    layout: "/admin",
    options: [],
  },
  {
    path: "/server_logs",
    name: "View Server Logs",
    icon: "ni ni-key-25 text-info",
    component: ServerLogs,
    hide_from_search: true,
    hide: true,
    layout: "/admin",
    options: [],
  },
  {
    path: "/reports",
    name: "Reports",
    icon: "ni ni-chart-bar-32 text-yellow",
    component: Reports,
    hide_from_search: true,
    options: getReportsArray(),
    layout: "/admin",
  },
  {
    path: "/pays",
    name: "Pays",
    icon: "ni ni-money-coins text-yellow",
    component: PaySelectionView,
    short_description:
      "View and edit pay runs, pays and payslips, generate banking files and report to the ATO via Single Touch Payroll.",
    options: [],
    layout: "/admin",
  },
  {
    path: "/pays/set_processed_date_for_pay_run",
    name: "Set Processed Date For Pay Run",
    icon: "fa-regular fa-xl fa-calendar text-yellow",
    component: SetProcessedDateView,
    short_description:
      "Edit the processed/paid date for chosen pays with a given pay run.",
    options: [],
    layout: "/admin",
    hide: true,
  },
  {
    path: "/pays/create_pays",
    name: "Create Pays",
    hide: true,
    icon: "ni ni-money-coins text-yellow",
    component: CreatePaysView,
    short_description:
      "Create additional out-of-cycle pays. Useful for bonuses, back payments or missed pay amounts.",
    options: [],
    layout: "/admin",
  },
  {
    path: "/pays/delete_pays",
    name: "Delete Pays",
    hide: true,
    icon: "ni ni-money-coins text-yellow",
    hide_from_search: true,
    component: DeletePaysView,
    options: [],
    layout: "/admin",
  },
  {
    path: "/pays/edit_pay",
    name: "Edit Pay",
    icon: "fa fa-edit text-yellow",
    component: EditPayView,
    hide_from_search: true,
    options: [],
    layout: "/admin",
    hide: true,
  },
  {
    path: "/pays/payslips_this_pay_run",
    name: "Payslips For This Pay Run",
    icon: "fa fa-edit text-yellow",
    component: PayslipsWizard,
    hide_from_search: true,
    options: [],
    layout: "/admin",
    hide: true,
  },
  {
    path: "/pays/payslips_multiple_pay_runs",
    name: "Payslips For Multiple Pay Runs",
    icon: "fa fa-edit text-yellow",
    component: PayslipsWizard,
    hide_from_search: true,
    options: [],
    layout: "/admin",
    hide: true,
  },
  {
    path: "/pays/pay_details",
    name: "Pay Details Report",
    icon: "fa fa-edit text-yellow",
    component: PayDetailsWizard,
    hide_from_search: true,
    options: [],
    layout: "/admin",
    hide: true,
  },
  {
    path: "/pays/currency_requirements_single",
    name: "Currency Requirements",
    icon: "fa fa-edit text-yellow",
    component: CurrencyRequirementsWizard,
    hide_from_search: true,
    options: [],
    layout: "/admin",
    hide: true,
  },
  {
    path: "/pays/currency_requirements_multiple",
    name: "Currency Requirements (Multiple)",
    icon: "fa fa-edit text-yellow",
    component: CurrencyRequirementsWizard,
    hide_from_search: true,
    options: [],
    layout: "/admin",
    hide: true,
  },
  {
    path: "/pays/direct_entry_this_pay_run",
    name: "Direct Entry For This Pay Run",
    icon: "fa fa-edit text-yellow",
    component: DirectEntryWizard,
    hide_from_search: true,
    options: [],
    layout: "/admin",
    hide: true,
  },
  {
    path: "/pays/direct_entry_multiple_pay_runs",
    name: "Direct Entry For Multiple Pay Runs",
    icon: "fa fa-edit text-yellow",
    component: DirectEntryWizard,
    hide_from_search: true,
    options: [],
    layout: "/admin",
    hide: true,
  },
  {
    path: "/pays/timeclock_portal_sync",
    name: "Timeclock/Portal Sync",
    icon: "fa fa-edit text-yellow",
    component: TimeclockSyncView,
    hide_from_search: true,
    options: [],
    layout: "/admin",
    hide: true,
  },
  {
    path: "/pays/leave-requests",
    name: "Manage Leave Requests",
    icon: "fa fa-plane text-yellow",
    component: LeaveRequestsView,
    hide_from_search: false,
    short_description: "Create and manage leave requests for your employees.",
    options: [],
    layout: "/admin",
    hide: true,
  },
  {
    path: "/pays/leave-request",
    name: "Leave Request",
    icon: "fa fa-edit text-yellow",
    component: LeaveRequestView,
    hide_from_search: true,
    options: [],
    layout: "/admin",
    hide: true,
  },
  {
    path: "/pays/timeclock_rounding_options",
    name: "Timeclock/Portal Rounding Options",
    icon: "fa fa-edit text-yellow",
    component: TimeclockRoundingOptions,
    hide_from_search: true,
    options: [],
    layout: "/admin",
    hide: true,
  },
  {
    path: "/pays/submit_single_touch",
    name: "Submit Single Touch Payroll",
    icon: "fa fa-edit text-yellow",
    component: SingleTouchWizard,
    hide_from_search: true,
    options: [],
    layout: "/admin",
    hide: true,
  },
  {
    path: "/pays/single_touch_mailbox",
    name: "Single Touch Mailbox",
    icon: "fa fa-edit text-yellow",
    component: SingleTouchMailboxView,
    hide_from_search: true,
    options: [],
    layout: "/admin",
    hide: true,
  },
  {
    path: "/pays/stp_mailbox_event",
    name: "Single Touch Event",
    icon: "fa fa-edit text-yellow",
    component: SingleTouchMailboxEvent,
    hide_from_search: true,
    options: [],
    layout: "/admin",
    hide: true,
  },
  {
    path: "/no_companies_exist",
    name: "New Company/Backup Prompt",
    icon: "ni ni-chart-bar-32 text-yellow",
    component: NewCompanyBackupPrompt,
    hide_from_search: true,
    hide: true,
    options: [],
    layout: "/admin",
  },
  {
    path: "/logout",
    name: "Logout",
    icon: "fa-regular fa-person-from-portal text-yellow",
    short_description: "Logout of your account.",
    component: Logout,
    options: [],
    layout: "/admin",
    hide: true,
  },
  {
    path: "/settings",
    name: "Settings",
    hide_from_search: true,
    icon: "fa-regular fa-gear text-yellow",
    layout: "/admin",
    options: [
      {
        name: "Super Funds",
        tags: ["super fund", "super funds", "superannuation"],
        icon: "fa-regular fa-hand-holding-seedling text-blue fa-xl width-40px",
        short_description:
          "View and edit program-wide super fund provider information.",
        path: "/settings/super_fund_providers",
        component: SuperFundProviders,
        layout: "/admin",
      },
      {
        name: "Display/Time Settings",
        tags: ["settings", "options", "widgets"],
        icon: "fa-regular fa-tools text-blue fa-xl width-40px",
        short_description: "View and edit program-wide settings.",
        path: "/settings/display_time_settings",
        component: DisplayTimeSettings,
        layout: "/admin",
      },
      {
        path: "/data-sharing",
        name: "Data Sharing",
        tags: ["data", "sharings", "accountant", "bookkeeper"],
        short_description:
          "Share data with an accountant or bookkeeper for review or processing.",
        icon: "fa-light fa-share   text-blue fa-xl width-40px",
        component: DataSharing,
        layout: "/admin",
        showWithoutCompany: true,
      },
      {
        path: "/user-management",
        name: "User Management",
        tags: ["access", "permission", "user", "management"],
        short_description:
          "Configure non-admin users to access your account, and manage their permissions.",
        icon: "fa-light fa-user-group text-blue fa-xl width-40px",
        component: UserManagement,
        layout: "/admin",
        showWithoutCompany: true,
      },
      {
        path: "/user-restricted",
        name: "User Restricted",
        tags: ["access", "permission", "user", "management", "restricted"],
        hide: true,
        hide_from_search: true,
        component: UserRestricted,
        layout: "/admin",
        showWithoutCompany: true,
      },
      {
        name: "Other Settings",
        tags: ["settings", "options", "widgets"],
        icon: "fa-regular fa-tools text-blue fa-xl width-40px",
        short_description: "View and edit sync and settings.",
        path: "/settings/other_settings",
        component: OtherSettings,
        layout: "/admin",
      },
      {
        name: "Banks",
        tags: ["bank", "bank codes"],
        icon: "fa-regular fa-building-columns text-blue fa-xl width-40px",
        short_description:
          "Manage banks and bank codes used for company bank accounts.",
        path: "/settings/banks",
        component: Banks,
        layout: "/admin",
      },
    ],
  },
  {
    path: "/super_fund_deposits",
    name: "Super Fund Deposits",
    icon: "ni ni-money-coins text-yellow",
    component: SuperFundDeposits,
    short_description: "View and manage superannuation payments.",
    options: [],
    layout: "/admin",
  },
  {
    name: "Create Deposits",
    tags: ["create deposits", "super", "superannuation", "payments"],
    icon: "fa-regular fa-building-columns text-blue fa-xl width-40px",
    short_description:
      "Collect super from pays and create superannuation payments for your employees.",
    path: "/super_fund_deposits/create_deposits",
    component: CreateMultipleSuperFundDeposits,
    layout: "/admin",
    hide: true,
    options: [],
  },
  {
    name: "SuperStream Contributions",
    tags: ["contributions", "super", "superannuation", "payments"],
    icon: "fa-regular fa-building-columns text-blue fa-xl width-40px",
    short_description:
      "Generate SuperStream messages and send payment information to your super fund providers.",
    path: "/super_fund_deposits/superstream_contribution",
    component: SuperStreamContributionWizard,
    layout: "/admin",
    hide: true,
    options: [],
  },
  {
    name: "SuperStream Direct Entry",
    tags: ["direct entry", "super", "superannuation", "payments", "banking"],
    icon: "fa-regular fa-university text-blue fa-xl width-40px",
    short_description:
      "Generate direct entry (ABA) file for payment of superannuation direct to your employees' super funds.",
    path: "/superstream/direct_entry",
    component: SuperStreamDirectEntryWizard,
    layout: "/admin",
    hide: true,
    options: [],
  },
  {
    name: "Create Blank Super Deposit",
    tags: ["new deposit", "blank", "super", "payments"],
    icon: "fa-regular fa-plus text-blue fa-xl width-40px",
    short_description:
      "Edit a specific super fund deposit to change deposit/payment date, super fund, amounts and more.",
    path: "/super_fund_deposits/create_blank_deposit",
    component: BlankSuperFundDeposit,
    layout: "/admin",
    hide: true,
    options: [],
  },
  {
    name: "Edit Super Deposit",
    tags: ["deposit", "super", "superannuation", "payments"],
    icon: "fa-regular fa-building-columns text-blue fa-xl width-40px",
    short_description:
      "Edit a specific super fund deposit to change deposit/payment date, super fund, amounts and more.",
    path: "/super_fund_deposits/edit_deposit",
    component: EditSuperFundDeposit,
    layout: "/admin",
    hide: true,
    options: [],
  },
  {
    path: "/superstream/superstream_mailbox",
    name: "SuperStream Mailbox",
    icon: "fa fa-edit text-yellow",
    component: SuperStreamMailboxView,
    hide_from_search: true,
    options: [],
    layout: "/admin",
    hide: true,
  },
  {
    path: "/superstream/superstream_mailbox_message",
    name: "SuperStream Message Details",
    icon: "fa fa-edit text-yellow",
    component: SuperStreamMailboxMessage,
    hide_from_search: true,
    options: [],
    layout: "/admin",
    hide: true,
  },
  {
    path: "/help",
    name: "Help",
    hide_from_search: true,
    icon: "fa-regular fa-square-question text-yellow",
    layout: "/admin",
    options: [
      {
        name: "Send Backup & Log To Support",
        tags: ["help", "support", "backup", "log", "error"],
        icon: "fa-regular fa-square-question text-blue fa-2xl width-40px",
        short_description:
          "Send a backup and log file to our support team for personalised assistance.",
        path: "/settings/send_backup_to_support",
        component: SendBackupToSupport,
        layout: "/admin",
      },
      {
        name: "Login To My Account",
        tags: ["account", "login", "portal", "subscription", "renewal"],
        icon: "fa-regular fa-user text-blue fa-2xl width-40px",
        short_description:
          "Login to you account to manage your subscription and renewals.",
        path: "/settings/login_to_my_account",
        component: LoginToMyAccount,
        layout: "/admin",
        hide: true,
      },
    ],
  },
  {
    path: "/create_fund_online_search",
    name: "Create Super Fund Online Search",
    tags: ["super fund", "super funds", "superannuation"],
    icon: "ni ni-settings-gear-65 text-yellow",
    short_description:
      "Search online for a super fund provider to add to your program.",
    hide: true,
    layout: "/admin",
    options: [],
    component: CreateSuperFundOnlineSearch,
  },
];

export default routes;
