import { Container, Row, Col, Button, Card, CardBody } from "reactstrap";
import { useEffect, useState, useContext } from "react";
import ComboBox from "../../components/Widgets/ComboBox.js";
import moment from "moment";
import LoadingSpinner from "../../components/Widgets/LoadingSpinner";
import AdminContext from "../../AdminContext";
import TableWidget from "../../components/Widgets/TableWidget";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import DateEdit from "../../components/Widgets/DateEdit";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Send from "@mui/icons-material/Send";
import Email from "@mui/icons-material/Email";
import AccessTime from "@mui/icons-material/AccessTime";
import Flight from "@mui/icons-material/Flight";
import QuestionModal from "../../components/Widgets/QuestionModal";
import PostCompleteModal from "./PostCompleteModal";
import dayjs from "dayjs";

const PaySelectionView = () => {
  const adminContext = useContext(AdminContext);
  const [isLoading, setIsLoading] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [form, setForm] = useState({});
  const [payRunHeader, setPayRunHeader] = useState("");

  // Menu buttons
  const [anchorEl, setAnchorEl] = useState(null);
  const payslipsOpen = Boolean(anchorEl && anchorEl.id === "payslips-button");
  const reportsOpen = Boolean(anchorEl && anchorEl.id === "reports-button");
  const stpOpen = Boolean(anchorEl && anchorEl.id === "stp-button");
  const syncOpen = Boolean(anchorEl && anchorEl.id === "sync-button");
  const [stpSent, setStpSent] = useState(false);
  const [stpSentAndSuccessful, setStpSentAndSuccessful] = useState(false);
  const [stpSentAndUnsuccessful, setStpSentAndUnsuccessful] = useState(false);
  const [stpReminderShown, setStpReminderShown] = useState(false);
  const [unsuccessfulStpReminderShown, setUnsuccessfulStpReminderShown] =
    useState(false);
  const [mostRecentSTPEvent, setMostRecentSTPEvent] = useState({});
  const [promptMessages, setPromptMessages] = useState([]);
  const [pendingLeaveRequestsCount, setPendingLeaveRequestsCount] = useState(0);
  const [pendingLeaveRequestsModalShown, setPendingLeaveRequestsModalShown] =
    useState(false);

  const directEntryOpen = Boolean(
    anchorEl && anchorEl.id === "direct-entry-button"
  );
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [currentPostCompleteMessageIndex, setCurrentPostCompleteMessageIndex] =
    useState(0);
  const comboData = { period_combo: adminContext.constants.PAY_PERIODS };

  const [selectedComboItem, setSelectedComboItem] = useState({
    period_combo: "",
  });

  // Update the form on data change
  const handleChange = (event) => {
    if (event.target.type === "checkbox") {
      setForm({ ...form, [event.target.name]: event.target.checked });
    } else if (event.target.type === "date") {
      const newDate = moment(new Date(event.target.value)).format("YYYY-MM-DD");
      setForm({ ...form, [event.target.name]: newDate });
    } else {
      setForm({ ...form, [event.target.name]: event.target.value });
    }
  };

  const createPayRunHeader = (responseData) => {
    // Convert YYYY-MM-DD to Tue, 20/01/2020
    let startDate = moment(responseData.start_date).format("dddd DD/MM/YYYY");
    let endDate = moment(responseData.end_date).format("dddd DD/MM/YYYY");
    return startDate + " - " + endDate;
  };

  const updateView = (responseData) => {
    adminContext.setPayRows(responseData.pays);

    // Set the pay period via the period combo
    setSelectedComboItem({
      ...selectedComboItem,
      period_combo: responseData.pay_period,
    });
    adminContext.setPayRun({
      ...adminContext.payRun,
      pay_period: responseData.pay_period,
      start_date: responseData.start_date,
      end_date: responseData.end_date,
    });

    if (responseData.most_recent_stp_event) {
      setMostRecentSTPEvent(responseData.most_recent_stp_event);
    }

    if (responseData.pending_leave_requests_count) {
      setPendingLeaveRequestsCount(responseData.pending_leave_requests_count);
    }

    setPayRunHeader(createPayRunHeader(responseData));
    if (responseData.single_touch_is_sent) {
      setStpSent(responseData.single_touch_is_sent);
      if (responseData.single_touch_is_sent_and_successful) {
        setStpSentAndSuccessful(
          responseData.single_touch_is_sent_and_successful
        );
      } else if (responseData.single_touch_is_sent_and_unsuccessful) {
        setStpSentAndUnsuccessful(
          responseData.single_touch_is_sent_and_unsuccessful
        );
      }
    }
    setIsLoading(false);
  };

  const initView = (current = false) => {
    setIsLoading(true);
    if (adminContext.company.id === undefined || !adminContext.company.id) {
      adminContext.history.push("/admin/entities/select");
      return;
    }
    let url =
      adminContext.constants.BASE_URL +
      `/pays/init?company_id=${adminContext.company.id}`;
    if (
      !current &&
      adminContext.payRun.end_date &&
      adminContext.payRun.pay_period
    ) {
      url += `&pay_run_date=${
        adminContext.payRun.end_date
      }&pay_period=${adminContext.payRun.pay_period.toUpperCase()}`;
    }
    adminContext.getRequest(url, (response) => {
      updateView(response.data);
    });
  };

  useEffect(initView, []);

  useEffect(() => {
    if (updating) {
      getPayRun(adminContext.payRun.end_date, adminContext.payRun.pay_period);
      setUpdating(false);
    }
  }, [adminContext.payRun]);

  const getPayRun = (payRunDate, payPeriod) => {
    setIsLoading(true);
    adminContext.getRequest(
      adminContext.constants.BASE_URL +
        `/pays/pay-run?company_id=${
          adminContext.company.id
        }&pay_run_date=${payRunDate}&pay_period=${payPeriod.toUpperCase()}`,
      (response) => {
        updateView(response.data);
      }
    );
  };

  // Rewrite the getNextPayRun and getLastPayRun functions to use the same function
  const getSidePayRun = (direction) => {
    // Get the last pay run date by subtracting days from the current enddate, depending on the pay period type (Weekly, etc.)
    let endDateSidePayPeriod = 0;
    let endDate = adminContext.payRun.end_date;
    let startDate = adminContext.payRun.start_date;
    if (startDate && direction < 0) {
      endDateSidePayPeriod = moment(startDate).subtract(1, "days");
    } else {
      switch (selectedComboItem["period_combo"]) {
        case "WEEKLY":
          endDateSidePayPeriod = moment(endDate).add(7 * direction, "days");
          break;
        case "FORTNIGHTLY":
          endDateSidePayPeriod = moment(endDate).add(14 * direction, "days");
          break;
        case "MONTHLY":
          endDateSidePayPeriod = moment(endDate).add(1 * direction, "months");
          break;
        default:
          endDateSidePayPeriod = moment(endDate).add(7 * direction, "days");
      }
    }

    setUpdating(true);
    setIsLoading(true);
    adminContext.setPayRun({
      ...adminContext.payRun,
      end_date: moment(endDateSidePayPeriod).format("YYYY-MM-DD"),
    });
  };

  const setPeriodCombo = (value) => {
    setSelectedComboItem({
      ...selectedComboItem,
      period_combo: value,
    });
    adminContext.setPayRun({
      ...adminContext.payRun,
      pay_period: value,
    });
    getPayRun(adminContext.payRun.end_date, value);
  };

  const getColumns = () => {
    const columns = [
      {
        label: "",
        accessor: "None",
        widget: "Link",
        args: {
          label: "",
          href: "/admin/pays/edit_pay",
          color: "secondary",
          icon: "fa-regular fa-edit fa-2xl mb-4 mx-3 my-3",
          className: "btn btn-sm btn-primary",
        },
      },
      {
        label: "Last Name",
        accessor: "employee_last_name",
        widget: "Text",
      },
      {
        label: "First Name",
        accessor: "employee_first_name",
        widget: "Text",
      },
      {
        label: "End Date",
        accessor: "date",
        widget: "DateLabel",
      },
      {
        label: "Processed Date",
        accessor: "nullable_processed_date",
        widget: "DateLabel",
      },
      {
        label: "Status",
        accessor: "status",
        widget: "Text",
      },
      {
        label: "Gross",
        accessor: "gross",
        widget: "MoneyLabel",
        align: "right",
      },
      {
        label: "Tax",
        accessor: "tax",
        widget: "MoneyLabel",
        align: "right",
      },
      {
        label: "Net",
        accessor: "net",
        widget: "MoneyLabel",
        align: "right",
      },
      {
        label: "Super",
        accessor: "super",
        widget: "MoneyLabel",
        align: "right",
      },
    ];
    if (adminContext.appConfig.allow_sort_by_payroll_number.value) {
      columns.push({
        label: "Payroll Number",
        accessor: "employee_payroll_number",
        widget: "Text",
      });
    }

    return columns;
  };

  const handlePostCompleteModalNext = () => {
    if (currentPostCompleteMessageIndex < promptMessages.length - 1) {
      // Move to the next message
      setCurrentPostCompleteMessageIndex((prevIndex) => prevIndex + 1);
    } else {
      setPromptMessages([]);
      // No more messages, handle the redirect and reset the message index
      setCurrentPostCompleteMessageIndex(0);
    }
  };

  const markAllComplete = () => {
    setIsLoading(true);
    adminContext.putRequest(
      adminContext.constants.BASE_URL +
        `/pays/mark-all-complete?company_id=${adminContext.company.id}&pay_run_date=${adminContext.payRun.end_date}&pay_period=${adminContext.payRun.pay_period}`,
      {
        pays: adminContext.payRows,
      },
      (response) => {
        setIsLoading(false);
        if (
          response.data.post_complete_prompt_messages !== undefined &&
          response.data.post_complete_prompt_messages.length > 0
        ) {
          setPromptMessages(response.data.post_complete_prompt_messages);
        }
        updateView(response.data);
      }
    );
  };

  const saveOrder = (order, orderBy) => {
    setIsLoading(true);
    adminContext.putRequest(
      adminContext.constants.BASE_URL + `/pays/save-order`,
      {
        order: order,
        order_by: orderBy,
      },
      (response) => {
        setIsLoading(false);
      }
    );
  };

  const STPButtonColor = () => {
    if (stpSentAndSuccessful) {
      return "success";
    } else if (stpSentAndUnsuccessful) {
      return "warning";
    } else if (stpSent) {
      return "secondary";
    } else {
      return "info";
    }
  };

  const submitSTP = () => {
    let url = "/admin/pays/submit_single_touch";
    adminContext.history.push(url);
  };

  const downloadPDF = async (reportType, base64Report) => {
    const fileName = reportType + ".pdf";
    await adminContext.handlePDFDownload(fileName, base64Report);
  };

  const downloadCSV = async (reportType, csvData) => {
    const datetimeString = dayjs().format("YYYY-MM-DD_HH-mm-ss");
    const fileName = reportType + "_" + datetimeString + ".csv";
    await adminContext.handleFileDownload(fileName, csvData, "text/csv");
  };

  const getPaySummaryDetailed = () => {
    setIsLoading(true);
    adminContext.postRequest(
      adminContext.constants.BASE_URL + `/pays/pay-summary-detailed-report`,
      { pays: adminContext.payRows },
      (response) => {
        setIsLoading(false);
        downloadPDF("Pay Summary Detailed", response.data.pdf_file_data);
      }
    );
  };

  const getGLPostingsPDF = () => {
    setIsLoading(true);
    adminContext.postRequest(
      adminContext.constants.BASE_URL + `/pays/gl-postings-pdf`,
      { pays: adminContext.payRows },
      (response) => {
        setIsLoading(false);
        downloadPDF("GL Postings", response.data.pdf_file_data);
      }
    );
  };

  const getGLPostingsCSV = () => {
    setIsLoading(true);
    adminContext.postRequest(
      adminContext.constants.BASE_URL + `/pays/gl-postings-csv`,
      { pays: adminContext.payRows },
      (response) => {
        setIsLoading(false);
        downloadCSV("GL Postings", response.data.csv_file_data);
      }
    );
  };

  return (
    <Container className="my-3 overflow-hidden" fluid>
      <Card>
        <CardBody>
          {!isLoading ? (
            <>
              <Row className="my-4 mx-auto align-items-center">
                <Col className="mx-auto" md="6">
                  <h1 className="text-center days-one h1-responsive">
                    <strong>{payRunHeader}</strong>
                  </h1>
                </Col>
                <Col className="mb-2 text-center">
                  <Button
                    className="btn btn-lg btn-secondary"
                    onClick={() => {
                      setIsLoading(true);
                      adminContext.setPayRun({
                        ...adminContext.payRun,
                        end_date: false,
                        pay_period: false,
                      });
                      initView(true);
                    }}
                  >
                    <i className=" mx-auto fa-regular fa-2xl mb-4 fa-home "></i>
                    <br /> Current Pay Run
                  </Button>
                </Col>

                <Col className="mx-auto px-1 full-width-on-sm   text-center mt-2">
                  <DateEdit
                    label="Jump to Pay Run Date"
                    value={adminContext.payRun.end_date}
                    onChange={(dateTime) => {
                      if (!dayjs(dateTime).isValid()) {
                        return;
                      }
                      setUpdating(true);
                      setIsLoading(true);
                      adminContext.setPayRun({
                        ...adminContext.payRun,
                        end_date: moment(new Date(dateTime)).format(
                          "YYYY-MM-DD"
                        ),
                      });
                    }}
                  />
                </Col>
                <Col className="mx-auto px-1 full-width-on-sm   text-center mt-2">
                  <Button
                    className="btn btn-lg btn-secondary"
                    disabled={
                      adminContext.completedPayRows().length === 0 ||
                      !adminContext.editAccess("pays")
                    }
                    onClick={() => {
                      adminContext.history.push(
                        "/admin/pays/set_processed_date_for_pay_run"
                      );
                    }}
                  >
                    <i className=" mx-auto fa-regular fa-2xl mb-4 fa-calendar width-100"></i>
                    <br /> Set Processed/Paid Date For This Pay Run
                  </Button>
                </Col>
              </Row>
              <Row className="my-2">
                <Col className="mx-auto px-1 full-width-on-sm   text-center">
                  <Row>
                    <Col md="4" className="mx-auto text-center">
                      <Button
                        className="btn mx-1 my-1 height-80 width-100 semi-active-button"
                        onClick={() => {
                          getSidePayRun(-1);
                        }}
                        color="success"
                      >
                        <i className=" mx-auto fa-regular fa-2xl mb-4 fa-arrow-circle-left" />
                        <br /> Last Pay Run
                      </Button>
                    </Col>
                    <Col md="4">
                      <Row>
                        <Col md="12" className="d-flex align-items-center">
                          <ComboBox
                            className="mx-2 mt-3 height-80 width-100"
                            name="period_combo"
                            onChange={handleChange}
                            comboData={comboData["period_combo"]}
                            selectedComboItem={
                              selectedComboItem["period_combo"]
                            }
                            setSelectedComboItem={setPeriodCombo}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col md="4" className="mx-auto text-center">
                      <Button
                        className="btn mx-1 my-1 height-80 width-100 semi-active-button"
                        onClick={() => {
                          getSidePayRun(1);
                        }}
                        color="success"
                      >
                        <i className=" mx-auto fa-regular fa-2xl mb-4 fa-arrow-circle-right" />
                        <br /> Next Pay Run
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col className="mx-auto px-1 full-width-on-sm  text-center">
                  <Button
                    className="btn btn-lg mx-1 my-1 height-80 width-100"
                    disabled={
                      !adminContext.payRows.length ||
                      !adminContext.editAccess("pays")
                    }
                    onClick={() => {
                      if (adminContext.payRows.length > 0) {
                        let url =
                          "/admin/pays/edit_pay?id=" +
                          adminContext.payRows[0].id;
                        adminContext.history.push(url);
                      }
                    }}
                    color="secondary"
                  >
                    <i className=" mx-auto fa-regular fa-2xl mb-4 fa-edit" />
                    <br /> Edit Pays
                  </Button>
                </Col>
                <Col className="mx-auto px-1 full-width-on-sm   text-center">
                  <Button
                    className="btn btn-lg mx-1 my-1 height-80 width-100"
                    onClick={() => {
                      let url = "/admin/pays/create_pays";
                      adminContext.history.push(url);
                    }}
                    disabled={!adminContext.editAccess("pays")}
                    color="secondary"
                  >
                    <i className=" mx-auto fa-regular fa-2xl mb-4 fa-plus text-success" />
                    <br /> Create Pays
                  </Button>
                </Col>
                <Col className="mx-auto px-1 full-width-on-sm   text-center">
                  <Button
                    className="btn btn-lg mx-1 my-1 height-80 width-100"
                    disabled={
                      adminContext.completedPayRows().length === 0 ||
                      !adminContext.editAccess("pays")
                    }
                    onClick={() => {
                      let url = "/admin/pays/delete_pays";
                      adminContext.history.push(url);
                    }}
                    color="secondary"
                  >
                    <i className=" mx-auto fa-regular fa-2xl mb-4 fa-times text-red" />
                    <br /> Delete Pays
                  </Button>
                </Col>
                <Col className="mx-auto px-1 full-width-on-sm   text-center">
                  <Button
                    className="btn btn-lg mx-1 my-1 height-80 width-100"
                    onClick={markAllComplete}
                    disabled={
                      adminContext.payRows.length === 0 ||
                      !adminContext.editAccess("pays")
                    }
                    color="secondary"
                  >
                    <i className=" mx-auto fa-regular fa-2xl mb-4 fa-check" />
                    <br /> Mark All As Complete
                  </Button>
                </Col>
                <Col className="mx-auto px-1 full-width-on-sm   text-center">
                  <Button
                    id="sync-button"
                    className="btn btn-lg mx-1 my-1 height-80 width-100"
                    aria-controls={syncOpen ? "sync-menu" : undefined}
                    aria-haspopup="true"
                    variant="contained"
                    aria-expanded={syncOpen ? "true" : undefined}
                    onClick={handleClick}
                    disabled={!adminContext.editAccess("pays")}
                  >
                    <i className="mx-auto fa-regular fa-2xl mb-4 fa-clock-rotate-left" />
                    <br /> Timeclock/Portal Sync
                  </Button>
                  <Menu
                    id="sync-menu"
                    aria-labelledby="sync-button"
                    anchorEl={anchorEl}
                    open={syncOpen}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={handleClose}>
                      <ListItemIcon>
                        <AccessTime />
                      </ListItemIcon>
                      <ListItemText
                        onClick={() =>
                          adminContext.history.push(
                            "/admin/pays/timeclock_portal_sync"
                          )
                        }
                      >
                        Import/Manage Shifts
                      </ListItemText>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      <ListItemIcon>
                        <Flight />
                      </ListItemIcon>
                      <ListItemText
                        onClick={() =>
                          adminContext.history.push(
                            "/admin/pays/leave-requests"
                          )
                        }
                      >
                        Import/Manage Leave Requests
                      </ListItemText>
                    </MenuItem>
                  </Menu>
                </Col>
                <Col className="mx-auto px-1 full-width-on-sm   text-center">
                  <Button
                    id="reports-button"
                    className="btn btn-lg mx-1 my-1 height-80 width-100"
                    aria-controls={reportsOpen ? "reports-menu" : undefined}
                    aria-haspopup="true"
                    variant="contained"
                    aria-expanded={reportsOpen ? "true" : undefined}
                    onClick={handleClick}
                  >
                    <i className="mx-auto fa fa-2xl mb-4 fa-address-book text-danger" />
                    <br />
                    Reports
                  </Button>
                  <Menu
                    id="reports-menu"
                    aria-labelledby="reports-button"
                    anchorEl={anchorEl}
                    open={reportsOpen}
                    onClose={handleClose}
                  >
                    <MenuItem
                      onClick={handleClose}
                      disabled={adminContext.completedPayRows().length === 0}
                    >
                      <ListItemIcon>
                        <i className=" mx-auto fa-regular fa-xl fa-money-bill text-primary" />
                      </ListItemIcon>
                      <ListItemText
                        onClick={() => {
                          let url = "/admin/pays/currency_requirements_single";
                          adminContext.history.push(url);
                        }}
                      >
                        Currency Requirements
                      </ListItemText>
                    </MenuItem>
                    {/* <MenuItem onClick={handleClose}>
                      <ListItemIcon>
                        <i className=" mx-auto fa-regular fa-xl fa-sack-dollar text-primary" />
                      </ListItemIcon>
                      <ListItemText
                        onClick={() => {
                          let url =
                            "/admin/pays/currency_requirements_multiple";
                          adminContext.history.push(url);
                        }}
                      >
                        Currency Requirements (Multiple)
                      </ListItemText>
                    </MenuItem> */}
                    <MenuItem
                      onClick={handleClose}
                      disabled={adminContext.completedPayRows().length === 0}
                    >
                      <ListItemIcon>
                        <i className=" mx-auto fa-regular fa-xl fa-receipt text-primary" />
                      </ListItemIcon>
                      <ListItemText onClick={getGLPostingsPDF}>
                        GL Postings (PDF/Print Preview)
                      </ListItemText>
                    </MenuItem>
                    <MenuItem
                      onClick={handleClose}
                      disabled={adminContext.completedPayRows().length === 0}
                    >
                      <ListItemIcon>
                        <i className=" mx-auto fa-regular fa-xl fa-receipt text-primary" />
                      </ListItemIcon>
                      <ListItemText onClick={getGLPostingsCSV}>
                        GL Postings (CSV Export)
                      </ListItemText>
                    </MenuItem>
                    <MenuItem
                      onClick={handleClose}
                      disabled={adminContext.completedPayRows().length === 0}
                    >
                      <ListItemIcon>
                        <i className=" mx-auto fa-regular fa-xl fa-chart-simple text-primary" />
                      </ListItemIcon>
                      <ListItemText
                        onClick={() => {
                          let url = "/admin/pays/pay_details";
                          adminContext.history.push(url);
                        }}
                      >
                        Pay Details
                      </ListItemText>
                    </MenuItem>
                    <MenuItem
                      onClick={handleClose}
                      disabled={adminContext.completedPayRows().length === 0}
                    >
                      <ListItemIcon>
                        <i className=" mx-auto fa-regular fa-xl fa-circle-bolt text-primary" />
                      </ListItemIcon>
                      <ListItemText onClick={getPaySummaryDetailed}>
                        Pay Summary (Detailed)
                      </ListItemText>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      <ListItemIcon>
                        <i className=" mx-auto fa-regular fa-xl fa-chart-simple text-primary" />
                      </ListItemIcon>
                      <ListItemText
                        onClick={() => {
                          adminContext.history.push(
                            "/admin/reports/pay_summary"
                          );
                        }}
                      >
                        Pay Summary
                      </ListItemText>
                    </MenuItem>
                  </Menu>
                </Col>
                <Col className="mx-auto px-1 full-width-on-sm   text-center">
                  <Button
                    id="payslips-button"
                    className="btn btn-lg mx-1 my-1 height-80 width-100"
                    aria-controls={payslipsOpen ? "payslips-menu" : undefined}
                    aria-haspopup="true"
                    variant="contained"
                    aria-expanded={payslipsOpen ? "true" : undefined}
                    onClick={handleClick}
                  >
                    <i className=" mx-auto fa-regular fa-2xl mb-4 fa-money-bill" />
                    <br />
                    Payslips
                  </Button>
                  <Menu
                    id="payslips-menu"
                    aria-labelledby="payslips-button"
                    anchorEl={anchorEl}
                    open={payslipsOpen}
                    onClose={handleClose}
                  >
                    <MenuItem
                      onClick={handleClose}
                      disabled={adminContext.completedPayRows().length === 0}
                    >
                      <ListItemIcon>
                        <i className=" mx-auto fa-regular fa-xl fa-calendar" />
                      </ListItemIcon>
                      <ListItemText
                        onClick={() => {
                          let url = "/admin/pays/payslips_this_pay_run";
                          adminContext.history.push(url);
                        }}
                      >
                        This Pay Run
                      </ListItemText>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      <ListItemIcon>
                        <i className=" mx-auto fa-regular fa-xl fa-calendars" />
                      </ListItemIcon>
                      <ListItemText
                        onClick={() => {
                          let url = "/admin/pays/payslips_multiple_pay_runs";
                          adminContext.history.push(url);
                        }}
                      >
                        Multiple Pay Runs
                      </ListItemText>
                    </MenuItem>
                  </Menu>
                </Col>
                <Col className="mx-auto px-1 full-width-on-sm   text-center">
                  <Button
                    id="direct-entry-button"
                    className="btn btn-lg mx-1 my-1 height-80 width-100"
                    aria-controls={
                      directEntryOpen ? "direct-entry-menu" : undefined
                    }
                    aria-haspopup="true"
                    variant="contained"
                    aria-expanded={directEntryOpen ? "true" : undefined}
                    onClick={handleClick}
                  >
                    <i className=" mx-auto fa-regular fa-2xl mb-4 fa-building-columns " />
                    <br /> Direct Entry
                  </Button>
                  <Menu
                    id="direct-entry-menu"
                    aria-labelledby="direct-entry-button"
                    anchorEl={anchorEl}
                    open={directEntryOpen}
                    onClose={handleClose}
                  >
                    <MenuItem
                      onClick={handleClose}
                      disabled={adminContext.completedPayRows().length === 0}
                    >
                      <ListItemIcon>
                        <i className=" mx-auto fa-regular fa-xl fa-calendar" />
                      </ListItemIcon>
                      <ListItemText
                        onClick={() => {
                          let url = "/admin/pays/direct_entry_this_pay_run";
                          adminContext.history.push(url);
                        }}
                      >
                        This Pay Run
                      </ListItemText>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      <ListItemIcon>
                        <i className=" mx-auto fa-regular fa-xl fa-calendars" />
                      </ListItemIcon>
                      <ListItemText
                        onClick={() => {
                          let url =
                            "/admin/pays/direct_entry_multiple_pay_runs";
                          adminContext.history.push(url);
                        }}
                      >
                        Multiple Pay Runs
                      </ListItemText>
                    </MenuItem>
                  </Menu>
                </Col>
                <Col className="mx-auto px-1 full-width-on-sm   text-center">
                  <Button
                    id="stp-button"
                    className="btn btn-lg mx-1 my-1 height-80 width-100"
                    color={STPButtonColor()}
                    aria-controls={stpOpen ? "stp-menu" : undefined}
                    aria-haspopup="true"
                    variant="contained"
                    aria-expanded={stpOpen ? "true" : undefined}
                    onClick={handleClick}
                  >
                    <i className=" mx-auto fa-regular fa-2xl mb-4 fa-business-time " />
                    <br /> Single Touch
                  </Button>
                  <Menu
                    id="stp-menu"
                    aria-labelledby="stp-button"
                    anchorEl={anchorEl}
                    open={stpOpen}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={handleClose}>
                      <ListItemIcon>
                        <Send />
                      </ListItemIcon>
                      <ListItemText onClick={submitSTP}>
                        Submit Payroll To ATO
                      </ListItemText>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      <ListItemIcon>
                        <Email />
                      </ListItemIcon>
                      <ListItemText
                        onClick={() => {
                          adminContext.history.push(
                            "/admin/pays/single_touch_mailbox"
                          );
                        }}
                      >
                        Single Touch Mailbox
                      </ListItemText>
                    </MenuItem>
                  </Menu>
                </Col>
              </Row>

              <Row className="d-block">
                <Col className="mx-4 my-3 overflow-scroll">
                  {adminContext.appConfig.pay_sort_by !== undefined &&
                    adminContext.appConfig.pay_sort_direction !== undefined && (
                      <TableWidget
                        title="Pays"
                        autoMinWidth={true}
                        orderBy={adminContext.appConfig.pay_sort_by.value}
                        order={adminContext.appConfig.pay_sort_direction.value}
                        rows={adminContext.payRows}
                        setRows={adminContext.setPayRows}
                        saveOrder={saveOrder}
                        columns={getColumns()}
                        total={true}
                        totalOmitCallback={(row) => {
                          return row.status === "PENDING";
                        }}
                        rowStyleCallback={(row) => {
                          if (row.employee.is_terminated) {
                            return "bg-terminated";
                          } else if (row.employee.locked) {
                            return "bg-locked";
                          }
                          if (row.status === "PENDING") {
                            return "font-italic";
                          }
                          return "";
                        }}
                      />
                    )}
                </Col>
              </Row>
              <QuestionModal
                isOpen={
                  !stpSent &&
                  adminContext.appConfig.remind_send_single_touch.value &&
                  adminContext.payRows.length > 0 &&
                  adminContext.payRows.length ===
                    adminContext.payRows.filter((row) => row.is_complete)
                      .length &&
                  !stpReminderShown
                }
                title=""
                content={
                  <p>
                    Your pay run is complete and has not yet been submitted to
                    the ATO. Would you like to submit it now?
                  </p>
                }
                onConfirm={submitSTP}
                onDeny={() => {
                  setStpReminderShown(true);
                }}
              />
              <QuestionModal
                isOpen={
                  pendingLeaveRequestsCount &&
                  pendingLeaveRequestsCount > 0 &&
                  !pendingLeaveRequestsModalShown
                }
                title=""
                content={
                  <p>
                    You have {pendingLeaveRequestsCount} pending leave request
                    {pendingLeaveRequestsCount > 1 ? "s" : ""}. Would you like
                    to open the leave requests screen now?
                  </p>
                }
                onConfirm={() =>
                  adminContext.history.push("/admin/pays/leave-requests")
                }
                onDeny={() => {
                  setPendingLeaveRequestsModalShown(true);
                }}
              />
              {mostRecentSTPEvent.error_message && (
                <QuestionModal
                  isOpen={
                    mostRecentSTPEvent.status !== null &&
                    mostRecentSTPEvent.status.toLowerCase() ===
                      "transmission unsuccessful" &&
                    !unsuccessfulStpReminderShown
                  }
                  title=""
                  content={
                    <>
                      <h1 className="my-3 text-center">
                        <i className=" mx-auto fa-regular fa-triangle-exclamation text-warning fa-2xl mb-4 my-3" />
                      </h1>
                      <h2>
                        Your Last STP Response From The ATO Was Unsuccessful
                      </h2>
                      <p>
                        The ATO has reported an error within the most recently
                        received STP response message for this company.
                      </p>
                      <p>
                        Date Sent:{" "}
                        {moment(mostRecentSTPEvent.timestamp_local).format(
                          "DD/MM/YYYY"
                        )}
                      </p>
                      <p>
                        Response Received:{" "}
                        {moment(
                          mostRecentSTPEvent.last_response_check_local
                        ).format("DD/MM/YYYY")}
                      </p>
                      <p>Error Message: {mostRecentSTPEvent.error_message}</p>
                      {mostRecentSTPEvent.error_message.includes(
                        "SBR.GEN.AUTH.006"
                      ) && (
                        <p>
                          <strong>
                            Please see our instructions on how to fix this error{" "}
                            <a
                              href="https://www.lightningpayroll.com.au/faq?selected=276"
                              target="_blank"
                              className="text-underline"
                            >
                              at our support page
                            </a>
                            .
                          </strong>
                        </p>
                      )}
                      <p>
                        More information is available in the Single Touch
                        Mailbox. Would you like to open it now?
                      </p>
                    </>
                  }
                  onConfirm={() =>
                    adminContext.history.push(
                      "/admin/pays/single_touch_mailbox"
                    )
                  }
                  onDeny={() => {
                    setUnsuccessfulStpReminderShown(true);
                  }}
                />
              )}

              <div className="mr-1 ml-3">
                <Row className="mx-3">Legend:</Row>
                <Row className="mx-3 text-center">
                  <Col className="bg-terminated border-1px">
                    <span>Terminated</span>
                  </Col>
                  <Col className="bg-locked border-1px">
                    <span>Locked</span>
                  </Col>
                  <Col className="font-italic border-1px">
                    <span>Pending</span>
                  </Col>
                </Row>
              </div>
            </>
          ) : (
            <LoadingSpinner />
          )}
        </CardBody>
      </Card>
      {promptMessages.length > 0 && (
        <PostCompleteModal
          messages={promptMessages}
          currentIndex={currentPostCompleteMessageIndex}
          onNext={handlePostCompleteModalNext}
        />
      )}
    </Container>
  );
};
export default PaySelectionView;
