import { useContext, useState, useEffect } from "react";
import AdminContext from "../../../AdminContext";
import Wizard from "../../../components/Widgets/Wizard";
import DateRangeView from "../../../components/Widgets/WizardViews/DateRangeView";
import SelectEmployeesView from "../../../components/Widgets/WizardViews/SelectEmployeesView";
import SelectPaysView from "../../../components/Widgets/WizardViews/SelectPaysView";
import ValidationPage from "../../../components/Widgets/WizardViews/ValidationPage";
import PreviewView from "./PreviewView";
import SaveFileView from "./SaveFileView";
import SummaryView from "./SummaryView";
import MoneyLabel from "../../../components/Widgets/MoneyLabel";

const DirectEntryWizard = ({ multiple = false }) => {
  const adminContext = useContext(AdminContext);
  const [step, setStep] = useState(0);
  const [nextEnabled, setNextEnabled] = useState(true);
  const [forceUpdateButtons, setForceUpdateButtons] = useState(false);
  const [buttonsVisible, setButtonsVisible] = useState(true);
  const [loading, setLoading] = useState(false);
  const [validationItems, setValidationItems] = useState([]);

  const convertDateFormat = (dateString) => {
    // Converts "dd/mm/yyyy" format to "yyyy-mm-dd" format
    const [day, month, year] = dateString.split("/");
    return `${year}-${month}-${day}`;
  };

  const getFormatNameForPay = (pay) => {
    return (
      <>
        Pay <MoneyLabel amount={pay.net._decimal} /> to{" "}
        {pay.employee.format_name} for {pay.date}
      </>
    );
  };

  const [wizardInfo, setWizardInfo] = useState({
    pays: [],
    selected_pays: [],
    employees: adminContext.company.employees.filter((e) => !e.is_terminated),
    selected_employees: adminContext.company.employees.filter(
      (e) => !e.is_terminated
    ),
    company: adminContext.company,
    start_date: convertDateFormat(
      adminContext.appConfig.report_start_date.value
    ),
    end_date: convertDateFormat(adminContext.appConfig.report_end_date.value),
    processing_date: new Date(),
    include_previous_pays: false,
    multiple_pay_runs: false,
    file_description: adminContext.company.aba_lodgement_reference,
    debit_lodgement_reference: adminContext.company.aba_lodgement_reference,
    default_filename: "pays",
    deposits: [],
    company_bank_account: adminContext.company.pays_bank_account,
    include_debit_entry: adminContext.company.include_debit_entry,
  });

  const [originalPays, setOriginalPays] = useState([]);

  const [rows, setRows] = useState({
    pays: [],
  });

  const [selectedRows, setSelectedRows] = useState({
    pays: [],
  });

  useEffect(() => {
    setButtonsVisible(!loading);
  }, [loading]);

  // file_description and debit_lodgement_reference should be the same. Update debit_lodgement_reference when file_description changes.
  useEffect(() => {
    setWizardInfo({
      ...wizardInfo,
      debit_lodgement_reference: wizardInfo.file_description,
    });
    // Also update the current company.aba_lodgement_reference
    adminContext.setCompany({
      ...adminContext.company,
      aba_lodgement_reference: wizardInfo.file_description,
    });
  }, [wizardInfo.file_description]);

  // Update the wizardInfo when the rows or selectedRows change.
  useEffect(() => {
    let filteredPays = getFilteredPays(rows.pays);
    let filteredSelectedPays = getFilteredPays(selectedRows.pays);
    setWizardInfo({
      ...wizardInfo,
      pays: filteredPays,
      selected_pays: filteredSelectedPays,
    });
  }, [rows, selectedRows]);

  useEffect(() => {
    // Get the current route from the url.
    // E.g. http://localhost:3000/admin/pays/direct_entry_multiple_pay_runs => direct_entry_multiple_pay_runs
    const currentRoute = window.location.pathname.split("/").pop();
    if (currentRoute.includes("multiple")) {
      setWizardInfo({
        ...wizardInfo,
        multiple_pay_runs: true,
      });
      setStep(0);
    } else {
      let pays = [];
      adminContext.completedPayRows().forEach((pay) => {
        pays.push({
          id: pay.id,
          format_name: getFormatNameForPay(pay),
          written_to_ABA: pay.written_to_ABA,
        });
      });
      let selected_pays = [];
      adminContext.completedPayRows().forEach((pay) => {
        selected_pays.push({
          id: pay.id,
          format_name: getFormatNameForPay(pay),
          written_to_ABA: pay.written_to_ABA,
        });
      });
      setWizardInfo({
        ...wizardInfo,
        pays: pays,
        selected_pays: selected_pays,
      });
      setStep(2);
    }
  }, []);

  const getFilteredPays = (pays, include_previous_pays) => {
    if (include_previous_pays === undefined) {
      include_previous_pays = wizardInfo.include_previous_pays;
    }
    if (include_previous_pays) {
      return pays;
    } else {
      return pays.filter((pay) => pay.written_to_ABA === false);
    }
  };

  const selectPaysFormSpec = {
    fixedTitle: true,
    title: "Select Pays",
    shortDescription: (
      <>
        This is a list of pays for employees that are set up to receive direct
        payments, and have not previously been included in a direct entry file.
        <br />
        If you wish to see pays that have previously been included, check the
        box marked <i>List pays previously included in a direct entry file</i>
        <br />
        Please select the pays to include in the direct entry file below, then
        click "Next".
      </>
    ),
    fields: [
      {
        label: "List pays previously included in a direct entry file",
        accessor: "include_previous_pays",
        widget: "CheckBox",
        args: {
          additionalChangeCallback: (e) => {
            // update the wizardInfo.pays depending on the value of pay.written_to_ABA
            let pays = getFilteredPays(originalPays, e.target.checked);
            setRows({ pays: pays });
            setSelectedRows({ pays: pays });
          },
        },
      },
      {
        label: "Payment Processing Date",
        accessor: "processing_date",
        widget: "DateEdit",
        args: {
          minDate: new Date(),
        },
      },
      {
        label: "Lodgement Reference",
        accessor: "file_description",
        required: true,
        validate: {
          minLength: { value: 1 },
          maxLength: { value: 18 },
        },
      },
      {
        label: "",
        accessor: "pays",
        widget: "ListWidget",
        validate: { required: false },
        args: {
          rows: rows,
          setRows: setRows,
          selectedRows: selectedRows,
          setSelectedRows: setSelectedRows,
          skipFilter: true,
          noTitleCase: true,
        },
      },
    ],
  };

  const handleNext = () => {
    if (wizardInfo.multiple_pay_runs) {
      if (step === 0) {
        // DateRangeView
        setStep(1); // SelectEmployeesView
      } else if (step === 1) {
        // SelectEmployeesView
        setStep(2); // SelectPaysView
      }
    }
    if (step < wizardSteps.length - 1) {
      setStep(step + 1);
    } else {
      adminContext.history.goBack();
    }
  };

  const handleBack = () => {
    if (step > 0) {
      setStep(step - 1);
      setNextEnabled(true);
    }
  };

  const validate = () => {
    setLoading(true);
    adminContext.postRequest(
      adminContext.constants.BASE_URL + "/pays/direct-entry-pays",
      wizardInfo,
      (response) => {
        if (response.data.validation_items) {
          if (response.data.validation_items.length === 0) {
            handleNext();
            return;
          }
          setValidationItems(response.data.validation_items);
        }
        setLoading(false);
      }
    );
  };

  const wizardSteps = [
    <DateRangeView
      setNextEnabled={setNextEnabled}
      wizardInfo={wizardInfo}
      setWizardInfo={setWizardInfo}
      loading={loading}
      setLoading={setLoading}
    />,
    <SelectEmployeesView
      setForceUpdateButtons={setForceUpdateButtons}
      setNextEnabled={setNextEnabled}
      wizardInfo={wizardInfo}
      setWizardInfo={setWizardInfo}
      loading={loading}
      setLoading={setLoading}
    />,
    <SelectPaysView
      setNextEnabled={setNextEnabled}
      wizardInfo={wizardInfo}
      setWizardInfo={setWizardInfo}
      setForceUpdateButtons={setForceUpdateButtons}
      getFormatNameForPay={getFormatNameForPay}
      formSpec={selectPaysFormSpec}
      rows={rows}
      setRows={setRows}
      selectedRows={selectedRows}
      setSelectedRows={setSelectedRows}
      setOriginalPays={setOriginalPays}
      setButtonsVisible={setButtonsVisible}
      loading={loading}
      setLoading={setLoading}
      getFilteredPays={getFilteredPays}
    />,
    <ValidationPage
      setNextEnabled={setNextEnabled}
      wizardInfo={wizardInfo}
      setWizardInfo={setWizardInfo}
      setButtonsVisible={setButtonsVisible}
      handleNext={handleNext}
      loading={loading}
      setLoading={setLoading}
      validate={validate}
      validationItems={validationItems}
    />,
    <PreviewView
      setNextEnabled={setNextEnabled}
      wizardInfo={wizardInfo}
      setWizardInfo={setWizardInfo}
      setButtonsVisible={setButtonsVisible}
      setForceUpdateButtons={setForceUpdateButtons}
      loading={loading}
      setLoading={setLoading}
    />,
    <SaveFileView
      setNextEnabled={setNextEnabled}
      wizardInfo={wizardInfo}
      setWizardInfo={setWizardInfo}
      loading={loading}
      setLoading={setLoading}
    />,
    <SummaryView
      setNextEnabled={setNextEnabled}
      wizardInfo={wizardInfo}
      setWizardInfo={setWizardInfo}
      setButtonsVisible={setButtonsVisible}
      loading={loading}
      setLoading={setLoading}
    />,
  ];

  return (
    <Wizard
      wizardSteps={wizardSteps}
      handleNext={handleNext}
      handleBack={handleBack}
      buttonsVisible={buttonsVisible}
      cancelCallback={adminContext.history.goBack}
      nextEnabled={nextEnabled}
      step={step}
      setStep={setStep}
      forceUpdateButtons={forceUpdateButtons}
      setForceUpdateButtons={setForceUpdateButtons}
      isBackDisabled={() => {
        return step === 0 || (step === 2 && !wizardInfo.multiple_pay_runs);
      }}
      getLastStep={() => {
        return wizardSteps.length - 1;
      }}
    />
  );
};
export default DirectEntryWizard;
