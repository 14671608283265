import { useState, useContext } from "react";
import LoadingSpinner from "../../components/Widgets/LoadingSpinner";
import ComboBox from "../../components/Widgets/ComboBox.js";
import AdminContext from "../../AdminContext";
import { Container, Card, CardBody, Row, Col, Alert } from "reactstrap";
import FormControlLabel from "@mui/material/FormControlLabel";
import CheckBox from "@mui/material/Checkbox";

const DisplayTimeSettings = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const adminContext = useContext(AdminContext);

  const saveChanges = (key, val) => {
    setIsLoading(true);

    // This variable will be true when the component is still mounted.
    let isMounted = true;

    adminContext.postRequest(
      adminContext.constants.BASE_URL + `/tools/app-config-item`,
      { key: key, value: val },
      (response) => {
        // Only update state if the component is still mounted.
        if (isMounted) {
          setIsLoading(false);
          setSuccessMessage("Changes saved successfully.");
          setTimeout(() => {
            // Also, only update state if the component is still mounted.
            if (isMounted) {
              setSuccessMessage("");
            }
          }, 3000);
        }
      }
    );

    // Cleanup function that will be run when the component unmounts.
    return () => {
      isMounted = false;
    };
  };

  const asDecimalCombo = {
    true: "As Decimal",
    false: "h m s Format",
  };

  const decimalPrecisionCombo = {
    0: "No Decimal places",
    1: "1 Decimal place",
    2: "2 Decimal places",
    3: "3 Decimal places",
    4: "4 Decimal places",
    5: "5 Decimal places",
  };

  return (
    <fieldset disabled={!adminContext.editAccess("settings")}>
      <Container className="mt-4" fluid>
        <Card className="bg-secondary shadow">
          <CardBody>
            {isLoading || !adminContext.appConfig ? (
              <LoadingSpinner />
            ) : (
              <>
                <Row className="mt-2">
                  <Col>
                    <h1 className="days-one">
                      General Settings (Display, time, etc.)
                    </h1>
                  </Col>
                  <Col>
                    {successMessage && (
                      <Alert className="my-2 text-center" color="success">
                        <strong>{successMessage}</strong>
                      </Alert>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormControlLabel
                      label="Use pay processed dates for calculating monthly super guarantee amounts?"
                      control={
                        <CheckBox
                          checked={
                            adminContext.appConfig
                              .use_processed_date_for_calculating_super.value
                          }
                          color="primary"
                          id="mastercheck"
                          onChange={() => {
                            saveChanges(
                              "use_processed_date_for_calculating_super",
                              !adminContext.appConfig
                                .use_processed_date_for_calculating_super.value
                            );
                          }}
                        />
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormControlLabel
                      label="Use pay processed dates for calculating YTD figures?"
                      control={
                        <CheckBox
                          checked={
                            adminContext.appConfig.use_processed_date_for_ytd
                              .value
                          }
                          color="primary"
                          id="mastercheck"
                          onChange={() => {
                            saveChanges(
                              "use_processed_date_for_ytd",
                              !adminContext.appConfig.use_processed_date_for_ytd
                                .value
                            );
                          }}
                        />
                      }
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col>
                    <ComboBox
                      label={
                        <>
                          Should times appear in 12hr{" "}
                          <strong>(e.g 3:30PM)</strong> or 24hr
                          <strong> (e.g 15:30)</strong> format?
                        </>
                      }
                      disabled={!adminContext.editAccess("settings")}
                      stretch={false}
                      className="mx-3"
                      name="hours_display"
                      comboData={{
                        "12 hr time": "12 hr time",
                        "24 hr time": "24 hr time",
                      }}
                      selectedComboItem={
                        adminContext.appConfig.hours_display.value
                      }
                      setSelectedComboItem={(option) => {
                        saveChanges("hours_display", option);
                      }}
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col>
                    <>
                      Should duration appear as a decimal{" "}
                      <strong>(e.g 12.34567)</strong> or{" "}
                      <strong>(e.g 12h 20m 24s)</strong>?
                    </>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col>
                    <ComboBox
                      label="... in App Widgets?"
                      stretch={false}
                      disabled={!adminContext.editAccess("settings")}
                      className="mx-3"
                      name="hours_as_decimal_widget"
                      comboData={asDecimalCombo}
                      selectedComboItem={String(
                        adminContext.appConfig.hours_as_decimal_widget.value
                      )}
                      setSelectedComboItem={(option) => {
                        const newValue = adminContext.getKeyByValue(
                          asDecimalCombo,
                          option
                        );
                        saveChanges(
                          "hours_as_decimal_widget",
                          JSON.parse(newValue)
                        );
                      }}
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col>
                    <ComboBox
                      label="... in Reports?"
                      stretch={false}
                      disabled={!adminContext.editAccess("settings")}
                      className="mx-3"
                      name="hours_as_decimal_report"
                      comboData={asDecimalCombo}
                      selectedComboItem={String(
                        adminContext.appConfig.hours_as_decimal_report.value
                      )}
                      setSelectedComboItem={(option) => {
                        const newValue = adminContext.getKeyByValue(
                          asDecimalCombo,
                          option
                        );
                        saveChanges(
                          "hours_as_decimal_report",
                          JSON.parse(newValue)
                        );
                      }}
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col>
                    <ComboBox
                      label={
                        <>
                          What decimal precision would you like in reports{" "}
                          <strong>(e.g 2 = 12.34)</strong>?
                        </>
                      }
                      stretch={false}
                      disabled={!adminContext.editAccess("settings")}
                      className="mx-3"
                      name="decimal_precision_for_reports"
                      comboData={decimalPrecisionCombo}
                      selectedComboItem={
                        adminContext.appConfig.decimal_precision_for_reports
                          .value
                      }
                      setSelectedComboItem={(option) => {
                        const newValue = adminContext.getKeyByValue(
                          decimalPrecisionCombo,
                          option
                        );
                        saveChanges("decimal_precision_for_reports", newValue);
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormControlLabel
                      label="Show employees' payroll numbers on the Pays screen?"
                      control={
                        <CheckBox
                          checked={
                            adminContext.appConfig.allow_sort_by_payroll_number
                              .value
                          }
                          color="primary"
                          id="mastercheck"
                          onChange={() => {
                            saveChanges(
                              "allow_sort_by_payroll_number",
                              !adminContext.appConfig
                                .allow_sort_by_payroll_number.value
                            );
                          }}
                        />
                      }
                    />
                  </Col>
                </Row>
              </>
            )}
          </CardBody>
        </Card>
      </Container>
    </fieldset>
  );
};
export default DisplayTimeSettings;
