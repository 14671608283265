import { useState, useEffect, useCallback, useContext } from "react";
import { Link } from "react-router-dom";
import AdminContext from "../../AdminContext";
import {
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Navbar,
  Container,
  Button,
  Modal,
  Row,
  Col,
} from "reactstrap";
import { FaWindowClose } from "react-icons/fa";
import routes from "../../variables/routes";
import LoadingSpinner from "../../components/Widgets/LoadingSpinner";
import LicenceDetails from "../../components/Widgets/LicenceDetails";
import axios from "axios";
import { getBaseUrl } from "../../variables/Config";

const AdminNavbar = (props) => {
  const adminContext = useContext(AdminContext);
  const [searchText, setSearchText] = useState("");
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [showLicenceDetails, setShowLicenceDetails] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [faqData, setFaqData] = useState([]);

  const submitSearch = (e) => {
    if (searchText === "") {
      return;
    }
    if (e.keyCode === undefined) {
      // Search button is clicked
      getSearchResults();
      setShowSearchResults(true);
    } else {
      // Enter key is pressed
      e.preventDefault();

      if (e.keyCode === 13) {
        e.preventDefault();
        getSearchResults();
        setShowSearchResults(true);
      }
    }
  };

  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {
      setShowSearchResults(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  const getLocalSearchResults = () => {
    // Search the routes' names and labels for the search text.
    // The the searchText is found in the name or label, then add it's label as a hyperlink wihtin an <ul> to the results.
    let results = {};
    let searchLowered = searchText.toLowerCase();

    if (searchLowered === "") {
      return results;
    }

    routes.forEach((route) => {
      if (route.layout === "/admin") {
        // If route.tags, search the text for the presence of each tag
        let found = false;
        if (route.tags) {
          route.tags.forEach((tag) => {
            if (searchLowered.toLowerCase().includes(tag.toLowerCase())) {
              found = true;
            }
          });
        }
        if (route.short_description) {
          if (
            route.short_description
              .toLowerCase()
              .includes(searchLowered.toLowerCase())
          ) {
            found = true;
          }
        }

        if (
          route.hide_from_search === undefined &&
          route.path &&
          !route.hide_from_search &&
          (found ||
            (route.name && route.name.toLowerCase().includes(searchLowered)) ||
            (route.label && route.label.includes(searchLowered)))
        ) {
          results["/admin" + route.path] = {
            link_text: route.label || route.name,
            short_description: "",
          };
          if (route.short_description !== undefined) {
            results["/admin" + route.path]["short_description"] =
              route.short_description;
          }
        } else if (route.options) {
          route.options.forEach((option) => {
            let found = false;
            if (option.tags) {
              option.tags.forEach((tag) => {
                if (searchLowered.toLowerCase().includes(tag.toLowerCase())) {
                  found = true;
                }
              });
            }
            if (option.short_description) {
              if (
                option.short_description
                  .toLowerCase()
                  .includes(searchLowered.toLowerCase())
              ) {
                found = true;
              }
            }
            if (
              option.hide_from_search === undefined &&
              !option.hide_from_search &&
              (found ||
                (option.name &&
                  option.name.toLowerCase().includes(searchLowered)) ||
                (option.label && option.label.includes(searchLowered)))
            ) {
              results["/admin" + option.path] = {
                link_text: route.name + ": " + (option.label || option.name),
                short_description: "",
              };
              if (option.short_description !== undefined) {
                results["/admin" + option.path]["short_description"] =
                  option.short_description;
              }
            }
          });
        }
      }
    });

    if (Object.keys(results).length === 0) {
      return (
        <ul className="mx-3">
          <li className="mx-3">No app locations found.</li>
        </ul>
      );
    }
    return (
      <div className="mx-3">
        <h2>App Locations</h2>
        <ul className="mx-3">
          {Object.keys(results).map((key) => {
            return (
              <li className="mx-3" key={key}>
                <Link to={key}>
                  <span
                    onClick={() => {
                      setShowSearchResults(false);
                    }}
                  >
                    {results[key]["link_text"]}
                  </span>
                </Link>
                {results[key].hasOwnProperty("short_description") &&
                  results[key]["short_description"] !== "" && (
                    <span className="text-muted">
                      {" "}
                      - {results[key]["short_description"]}
                    </span>
                  )}
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  const processFaqSearch = (faqs) => {
    let faq_id_points = [];
    let results = [];
    let input_words = searchText.split(" ");
    let valid_words = [];
    for (let i = 0; i < input_words.length; i++) {
      if (input_words[i].length > 2) {
        valid_words.push(input_words[i].trim());
      }
    }
    let original_valid_words = valid_words.slice();

    // Include multi word combinations in valid words lookup array, do not process the multis repeatedly, only original input words
    for (let i = 0; i < original_valid_words.length; i++) {
      let current_word = original_valid_words[i];
      for (let next = i + 1; next < original_valid_words.length; next++) {
        current_word = current_word + " " + original_valid_words[next].trim();
        valid_words.push(current_word);
      }
    }

    for (let i = 0; i < faqs.length; i++) {
      let faq = faqs[i];
      let faq_id_point = {};
      faq_id_point["id"] = faq.id;
      faq_id_point["points"] = 0;

      for (let j = 0; j < valid_words.length; j++) {
        let word = valid_words[j];
        if (
          faq.category.toLowerCase().includes(word.toLowerCase()) ||
          faq.heading.toLowerCase().includes(word.toLowerCase()) ||
          faq.message.toLowerCase().includes(word.toLowerCase())
        ) {
          faq_id_point["points"]++;
        }
      }

      faq_id_points.push(faq_id_point);
    }

    faq_id_points = faq_id_points.sort(function (a, b) {
      return a.points <= b.points;
    });

    // Establish the high score
    let high_score = 0;
    for (let i = 0; i < faq_id_points.length; i++) {
      let current_faq = faq_id_points[i];
      if (current_faq["points"] > 0 && current_faq["points"] >= high_score) {
        high_score = current_faq["points"];
      }
    }

    // Hide and show those which meet the high score
    for (let i = 0; i < faq_id_points.length; i++) {
      let current_faq = faq_id_points[i];
      if (current_faq["points"] > 0 && current_faq["points"] >= high_score) {
        results.push(current_faq);
      } else if (valid_words.length) {
        // Remove the current_faq if it is in results
        let index = results.indexOf(current_faq);
        if (index > -1) {
          results.splice(index, 1);
        }
      }
    }

    if (results.length === 0) {
      return (
        <ul className="mx-3">
          <li className="mx-3">No support content found.</li>
        </ul>
      );
    }

    return (
      <div className="mx-3">
        <h2>Support Articles/FAQs</h2>
        <ul className="mx-3">
          {results.map((result) => {
            let faq = faqs.find((faq) => faq.id === result.id);
            return (
              <li className="mx-3" key={result.id}>
                <a
                  href={`https://www.lightningpayroll.com.au/faq?selected=${result.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span
                    onClick={() => {
                      setShowSearchResults(false);
                    }}
                  >
                    {faq.category}: {faq.heading}
                  </span>
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  const getSearchResults = async () => {
    setIsLoading(true);
    // Create axios get request to url. If the request is successful, return the response. If unauthorized, redirect to login page.
    await axios
      .get(`${getBaseUrl()}/tools/faq-data`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((response) => {
        // Update the token cookie with the new token
        const minutes = 30;
        const oneDay = 24 * 60;
        const expires = minutes / oneDay;

        const tokenData = JSON.stringify({
          token: response.data.access_token,
          expires: expires,
        });

        localStorage.setItem("token", tokenData);

        let faqContent = "";
        if (response.data.faq_data) {
          let processed = processFaqSearch(response.data.faq_data);
          if (processed) {
            faqContent = processed;
          }
        }

        setFaqData(faqContent);
        setIsLoading(false);
      });
  };

  return (
    <>
      <Navbar
        className="navbar-dark bg-default width-100"
        expand="lg"
        id="navbar-main"
      >
        <Container fluid>
          <div className="d-flex align-items-center row width-100 mx-1 mt-3">
            <div
              className={`${
                adminContext.mobileNavVisible || window.innerWidth >= 992
                  ? " row width-100-on-lg"
                  : " row d-none"
              }`}
            >
              <Col lg="5" md="6" className="vertical-center-flex">
                {adminContext.company && adminContext.company.name && (
                  <div className="d-flex align-items-center">
                    <h4 className="text-white mb-0 mr-3">
                      {adminContext.company.name}
                    </h4>
                    <br />
                    <span className="text-white-50">
                      {adminContext.ssid ? `SSID: ${adminContext.ssid}` : ""}
                    </span>
                  </div>
                )}
              </Col>

              <Col
                lg="3"
                md="12"
                className="d-flex align-items-center justify-content-center"
              >
                <Form
                  onSubmit={(e) => e.preventDefault()}
                  className="navbar-search navbar-search-dark form-inline my-sm-1 w-100"
                >
                  <FormGroup className="mr-md-3 mt-3 mt-md-0 text-center text-sm-right  width-100">
                    {adminContext.company.id && (
                      <InputGroup className="input-group-alternative width-100">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i
                              name="search-button"
                              onClick={(e) => submitSearch(e)}
                              className="fa-brands fa-searchengin fa-2xl text-yellow"
                            />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          value={searchText}
                          onChange={(e) => setSearchText(e.target.value)}
                          onKeyUp={submitSearch}
                          placeholder=""
                          type="text"
                        />
                      </InputGroup>
                    )}
                  </FormGroup>
                </Form>
              </Col>
              {!(adminContext.user && adminContext.user.user_companies) ? (
                <Col lg="2" md="6">
                  <div
                    className="pointer-cursor my-1 py-0 text-center border border-radius-45 pointer-cursor hover-animate mx-auto font-weight-bold text-white flex-fill py-1"
                    onClick={() =>
                      adminContext.history.push(
                        "/admin/settings/login_to_my_account"
                      )
                    }
                  >
                    <i className="fa-regular fa-user" />{" "}
                    <span className="mx-2">My Account</span>
                  </div>
                </Col>
              ) : null}

              <Col
                lg={
                  adminContext.user && adminContext.user.user_companies
                    ? "4"
                    : "2"
                }
                md="6"
              >
                <div
                  className="my-1 py-0 text-center border border-radius-45 pointer-cursor hover-animate mx-0 font-weight-bold text-white flex-fill py-1"
                  onClick={() => setShowLicenceDetails(true)}
                >
                  <i className="fa-regular fa-money-check-alt" /> Licence
                  Details
                </div>
              </Col>
            </div>
            <Col
              className="d-xs-block d-sm-block d-md-block d-lg-none text-white text-center mt-2"
              xs="12"
              sm="12"
            >
              <i
                className={
                  "fa-regular fa-2xl text-yellow " +
                  (adminContext.mobileNavVisible
                    ? "fa-chevron-up "
                    : "fa-chevron-down")
                }
                onClick={() =>
                  adminContext.setMobileNavVisible(
                    !adminContext.mobileNavVisible
                  )
                }
              />
            </Col>
          </div>
        </Container>
      </Navbar>
      <LicenceDetails
        showLicenceDetails={showLicenceDetails}
        setShowLicenceDetails={setShowLicenceDetails}
      />

      <Modal
        centered={true}
        isOpen={showSearchResults}
        contentLabel="Search Results"
      >
        <Container className="my-3">
          <Row>
            <Col></Col>
            <Col>
              <h1 className="days-one text-center my-2">Search Results</h1>
              <p className="text-center text-muted">
                Input: <strong>{searchText}</strong>
              </p>
            </Col>
            <Col>
              {" "}
              <Button
                color="secondary"
                onClick={() => setShowSearchResults(false)}
                className="float-right my-3 mx-5"
                focus={showSearchResults}
              >
                <FaWindowClose className="mr-3 mb-1" />
                Close
              </Button>
            </Col>
          </Row>

          {isLoading ? (
            <LoadingSpinner fit={true} />
          ) : (
            <>
              {getLocalSearchResults()} {faqData}
            </>
          )}
        </Container>
      </Modal>
    </>
  );
};

export default AdminNavbar;
