import TableWidget from "../../../components/Widgets/TableWidget";
import moment from "moment";

const EditPayBackPayments = ({
  adminContext,
  setSaving,
  getBackPaymentTaxMethodComboData,
}) => {
  return (
    <>
      <TableWidget
        label="Back Payments"
        rows={adminContext.pay.back_payments}
        editRowSaveCallback={(newBackPayment) => {
          setSaving(true);
          let backPayments;
          if (newBackPayment.id === null) {
            backPayments = adminContext.pay.back_payments.concat([
              newBackPayment,
            ]);
          } else {
            backPayments = adminContext.pay.back_payments.map((backPayment) => {
              if (backPayment.id === newBackPayment.id) {
                return newBackPayment;
              }
              return backPayment;
            });
          }
          adminContext.setPay({
            ...adminContext.pay,
            back_payments: backPayments,
          });
        }}
        columns={[
          {
            label: "Description",
            accessor: "description",
          },
          {
            label: "Amount",
            accessor: "amount",
            widget: "MoneyLabel",
          },
          {
            label: "STP Category",
            accessor: "stp_category",
            widget: "Text",
          },
          {
            label: "Included In Super?",
            accessor: "is_included_in_super_calculations",
            widget: "BooleanLabel",
          },

          {
            label: "Tax Method",
            accessor: "tax_method_display",
          },
        ]}
        appendRowCallback={() => {
          let endDate = moment(adminContext.payRun.end_date).format(
            "YYYY-MM-DD"
          );
          let payPeriod = adminContext.payRun.pay_period;
          let startDate;
          if (payPeriod === "WEEKLY") {
            // deduct 7 days from the end date
            startDate = moment(adminContext.payRun.end_date)
              .subtract(7, "days")
              .format("YYYY-MM-DD");
          } else if (payPeriod === "FORTNIGHTLY") {
            // deduct 14 days from the end date
            startDate = moment(adminContext.payRun.end_date)
              .subtract(14, "days")
              .format("YYYY-MM-DD");
          } else if (payPeriod === "MONTHLY") {
            // deduct 1 month from the end date
            startDate = moment(adminContext.payRun.end_date)
              .subtract(1, "months")
              .format("YYYY-MM-DD");
          }
          return {
            id: null,
            pay_id: adminContext.pay.id,
            description: "Back Payment",
            stp_category: "Gross",
            is_included_in_super_calculations: true,
            amount: { _decimal: 0 },
            start_date: startDate,
            end_date: endDate,
            tax_method: "METHOD_B2",
          };
        }}
        deleteConfirmationAttributes={["description", "amount"]}
        deleteRowCallback={(idToDelete) => {
          setSaving(true);
          adminContext.setPay({
            ...adminContext.pay,
            back_payments: adminContext.pay.back_payments.filter(
              (backPayment) => {
                return backPayment.id !== idToDelete;
              }
            ),
          });
        }}
        formSpec={{
          title: "Back Payment",
          shortDescription: (
            <>
              Below is where you can define a back payment for{" "}
              <strong>
                {adminContext.pay.employee.first_name}{" "}
                {adminContext.pay.employee.last_name}.
              </strong>
            </>
          ),
          validateFormCallback: (back_payment) => {
            if (
              back_payment.tax_method !== "NORMAL_EARNINGS" &&
              back_payment.amount._decimal < 0
            ) {
              return "Please choose 'Normal Earnings' for the tax method when entering a negative amount.";
            }
            if (
              isNaN(back_payment.amount._decimal) ||
              Number(back_payment.amount._decimal) === 0
            ) {
              return "Please enter a valid amount.";
            }
            if (
              ["METHOD_A_WHOLE_YEAR", "NORMAL_EARNINGS", "METHOD_B2"].includes(
                back_payment.tax_method
              )
            ) {
              return "";
            }

            let dateRegex = /^\d{4}-\d{2}-\d{2}$/;
            if (
              !dateRegex.test(back_payment.start_date) ||
              !dateRegex.test(back_payment.end_date)
            ) {
              return "Please enter a valid start and end date.";
            }

            if (back_payment.start_date > back_payment.end_date) {
              return "The start date must be before the end date.";
            }

            // Also make sure the start/end dates are not in the future
            if (back_payment.start_date > new Date()) {
              return "The start date must not be in the future.";
            }
            if (back_payment.end_date > new Date()) {
              return "The end date must not be in the future.";
            }

            return "";
          },
          fields: [
            {
              label: "Description",
              accessor: "description",
              widget: "Text",
            },
            {
              label: "Included In Super Calculations?",
              accessor: "is_included_in_super_calculations",
              widget: "CheckBox",
            },
            {
              label: "STP Category",
              accessor: "stp_category",
              widget: "ComboBox",
              args: {
                comboDataCallback: () => {
                  return {
                    "Bonus/Commission (Non Director's Fees)":
                      "Bonus/Commission (Non Director's Fees)",
                    "Director's Fees": "Director's Fees",
                    Gross: "Gross",
                    "Itemised AllowancesAD":
                      "Itemised Allowances - Award Transport",
                    "Itemised AllowancesCD":
                      "Itemised Allowances - Cents per KM",
                    "Itemised AllowancesKN": "Itemised Allowances - Tasks",
                    "Itemised AllowancesLD": "Itemised Allowances - Laundry",
                    "Itemised AllowancesMD":
                      "Itemised Allowances - Overtime Meals",
                    "Itemised AllowancesOD": "Itemised Allowances - Other",
                    "Itemised AllowancesQN":
                      "Itemised Allowances - Qualifications/Certificates",
                    "Itemised AllowancesRD":
                      "Itemised Allowances - Domestic & Overseas Travel/Accommodation",
                    "Itemised AllowancesTD": "Itemised Allowances - Tools",
                    LeaveA: "Leave - Ancillary",
                    LeaveC: "Leave - Cash Out",
                    LeaveO: "Leave - Other/General",
                    LeaveP: "Leave - Paid Parental",
                    LeaveU: "Leave - Unused On Termination",
                    LeaveW: "Leave - Workers Comp",
                    Overtime: "Overtime",
                    Unclassified: "Unclassified",
                  };
                },
              },
            },
            {
              label: "Amount",
              accessor: "amount",
              widget: "MoneyEdit",
            },
            {
              label: "Tax Method",
              accessor: "tax_method",
              widget: "ComboBox",
              args: {
                comboDataCallback: getBackPaymentTaxMethodComboData,
              },
            },
            {
              label: "",
              accessor: "tax_method_faq",
              widget: "Label",
              valueCallback: (row) => {
                return (
                  <>
                    Click{" "}
                    <a href="https://www.lightningpayroll.com.au/faq?selected=208">
                      <strong>here</strong>
                    </a>{" "}
                    for more information on tax methods.
                  </>
                );
              },
            },

            {
              label: "Start Date",
              accessor: "start_date",
              widget: "DateEdit",
              args: {
                visibleCallback: (row) => {
                  return (
                    !row.is_return_to_work &&
                    (row.tax_method == "METHOD_A_SPECIFIC_RANGE" ||
                      row.tax_method == "METHOD_B1")
                  );
                },
              },
            },
            {
              label: "End Date",
              accessor: "end_date",
              widget: "DateEdit",
              args: {
                visibleCallback: (row) => {
                  return (
                    !row.is_return_to_work &&
                    (row.tax_method == "METHOD_A_SPECIFIC_RANGE" ||
                      row.tax_method == "METHOD_B1")
                  );
                },
              },
            },
          ],
        }}
      />
    </>
  );
};
export default EditPayBackPayments;
